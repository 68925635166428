import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors } from './colors';

export const StyledInlineError = styled.span`/* stylelint-disable-line */
  color: ${colors.red};

  &:after {
    display: inline-block;
    content: "⚠️ ";
  }
`;

export const InlineError = ({ errors, message, name }) => {
  while (name.includes(`.`)) {
    const tempName = name.substring(0, name.indexOf(`.`));
    errors = errors[tempName];
    name = name.substring(name.indexOf(`.`) + 1);
  }

  return errors[name] ?
    <div>
      <StyledInlineError>
        {errors[name].message || message}
      </StyledInlineError>
    </div> :
    null; // NOTE: Don't change ternary to &&
};

InlineError.propTypes = {
  errors: PropTypes.object.isRequired,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
};
