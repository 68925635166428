export const colors = {
  black: `#000000`,
  dark_grey: `#d1d2d4`,
  dark_grey_blue: `#262b31`,
  green: `#00A04a`,
  green_gradient: `#006833`,
  grey_blue: `#323841`,
  light_grey: `#efefef`,
  medium_blue: `#1e7999`,
  muted: `#4C5A6B`,
  muted_gradient: `#4C5A6B`,
  red: `#df1f26`,
  red_gradient: `#892920`,
  white: `#FFFFFF`,
  women_grey: `#939597`,
  women_grey_gradient: `#262626`,
  yellow: `#fec93e`,
  yellow_gradient: `#b1a700`,
};
