import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './notification.scss';

/**
 * notification types:
 *  success
 *  danger
 *  info
 *  default
 *  warning
 */

export const triggerNotification = ({ message, title, variant }) => {
  const notification = {
    animationIn: [ `animated`, `fadeIn` ],
    animationOut: [ `animated`, `fadeOut` ],
    container: `bottom-left`,
    dismiss: {
      duration: 3500,
      onScreen: true,
    },
    insert: `bottom`,
    message: <div aria-live={variant === `success` ? `polite` : `assertive`}>{ message }</div>,
    title,
    type: variant,
    width: 275,
  };
  store.addNotification(notification);
};

export const handleSuccess = message => {
  triggerNotification({
    message,
    title: `Success`,
    variant: `success`,
  });
};

export const handleError = err => {
  if (err.message.includes(`Internal Server Error -`)) {
    err.message = err.message.replace(`Internal Server Error -`, ``);
  }
  const message = err.message || `An unknown error has occurred`;
  triggerNotification({
    message,
    title: `Error`,
    variant: `danger`,
  });
};

export const handleValidationError = err => {
  const message = err.message || `An unknown error has occurred`;
  triggerNotification({
    message,
    title: `Warning`,
    variant: `warning`,
  });
};

export const handleAsync = func => async (...args) => {
  try {
    return await func(...args);
  } catch (err) {
    handleError(err);
  }
};
