import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { dayjs } from 'shared/utils';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useForm } from 'react-hook-form';
import { CloseButton, Modal } from 'react-bootstrap';
import { handleError, InlineError, ModulePopover, PromiseForm } from 'shared/A-UI';
import { OffenderService, ProgramService } from 'shared/services';
import { useAuth } from 'shared/providers';
import debounce from 'debounce-promise';

export const UpdateProgramAssignment = ({
  assignment,
  isModalShown,
  offender,
  onClose,
  processAssignment,
}) => {
  const { terms: { offender_term } } = useAuth();
  const [ _offender, setOffender ] = useState(offender);
  const [ program, setProgram ] = useState({});
  const [ releaseReasons, setReleaseReasons ] = useState([]);
  const [ outcomeAchieved, setOutcomeAchieved ] = useState([]);
  const [ assessments, setAssessment ] = useState([]);
  const [ offenses, setOffense ] = useState([]);
  const [ _assignment, setAssignment ] = useState(assignment);
  const [ defaultReleaseReason, setDefaultReleaseReason ] = useState();
  const [ defaultOutcomeAchieve, setDefaultOutcomeAchieve ] = useState();
  const {
    formState: {
      errors,
    },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      family_hours: _assignment.family_hours,
      hours_completed: _assignment.hours_completed,
    },
  });

  useEffect(() => {
    register(`release_reason_id`, { value: _assignment?.release_reason_id });
    register(`outcome_achieved`, { value: _assignment?.outcome_achieved });
    register(`assessment`, { value: initialAssessment ? JSON.stringify(initialAssessment) : null });
    register(`offense`, { value: initialOffense ? JSON.stringify(initialOffense) : null });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ register ]);

  useEffect(() => {
    const fetchData = async () => {
      const reasons = await ProgramService.getReleaseReasonList();
      const formatted_reasons = reasons.map(({ id, name }) => ({ label: name, value: id }));
      setReleaseReasons(formatted_reasons);

      const _default = formatted_reasons.find(releaseReason => releaseReason.value === _assignment.release_reason_id);
      setDefaultReleaseReason(_default);
    };
    fetchData();
  }, [ _assignment.release_reason_id ]);

  useEffect(() => {

    _assignment.admit_date =
      _assignment.admit_date ?
        dayjs(_assignment.admit_date).format(`YYYY-MM-DD`) === `Invalid Date` ?
          dayjs(_assignment.admit_date, `MM-DD-YYYY`).format(`YYYY-MM-DD`) :
          dayjs(_assignment.admit_date).format(`YYYY-MM-DD`) :
        ``;
    _assignment.release_date =
      _assignment.release_date ?
        dayjs(_assignment.release_date).format(`YYYY-MM-DD`) === `Invalid Date` ?
          dayjs(_assignment.release_date, `MM-DD-YYYY`).format(`YYYY-MM-DD`) :
          dayjs(_assignment.release_date).format(`YYYY-MM-DD`) :
        ``;
    _assignment.referral_date =
      _assignment.referral_date ?
        dayjs(_assignment.referral_date).format(`YYYY-MM-DD`) === `Invalid Date` ?
          dayjs(_assignment.referral_date, `MM-DD-YYYY`).format(`YYYY-MM-DD`) :
          dayjs(_assignment.referral_date).format(`YYYY-MM-DD`) :
        ``;
  }, [ _assignment ]);

  useEffect(() => {
    ProgramService.getDetails(_assignment.program_id).then(setProgram);
  }, [ _assignment.program_id ]);

  const searchOffenders = async text => {
    const offenders = await OffenderService.search(text, true, true);

    return offenders.map(o => ({
      label: `${o.first_name} ${o.last_name}`,
      value: o,
    }));
  };

  useEffect(() => {
    if (_offender) {
      OffenderService.getAssessmentInfo(_offender?.id).then(setAssessment);
      OffenderService.getOffenseInfo(_offender?.id).then(setOffense);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ _offender?.id ]);

  const handleSelection = (_value, { name }) => {

    if (_value) {
      const { value } = _value;
      setValue(name, value);
      if (name === `release_reason_id`) {
        setDefaultReleaseReason(releaseReasons.find(releaseReason => releaseReason.value === value));
      }
      else if (name === `outcome_achieved`) {
        setDefaultOutcomeAchieve(outcomeAchieved.find(outcomeAchieve => outcomeAchieve.value === value));
      }
      else if (name === `offender`) {
        setOffender(value);
        setValue(`assessment`, null);
        setValue(`offense`, null);
      }
    }
    else {
      setValue(name, null);
      if (name === `release_reason_id`) {
        setDefaultReleaseReason(null);
      }
      else if (name === `outcome_achieved`) {
        setDefaultOutcomeAchieve(null);
      }
      else if (name === `offender`) {
        setOffender(null);
        setValue(`assessment`, null);
        setValue(`offense`, null);
      }
    }
  };

  const handleReleaseDate = (e) => {
    if (e.target.value === ``) {
      setAssignment({ ..._assignment, family_hours: ``, hours_completed: ``, release_date: `` });
      setValue(`family_hours`, null);
      setValue(`hours_completed`, null);
      setDefaultReleaseReason(null);
      setDefaultOutcomeAchieve(null);
    }
    else {
      setAssignment({ ..._assignment, release_date: e.target.value });
    }
  };

  const handleReferralDate = (e) => {
    if (e.target.value !== ``) {
      setAssignment({ ..._assignment, referral_date: e.target.value });
    }
  };

  const handleAdmissionDate = (e) => {
    if (e.target.value !== ``) {
      setAssignment({ ..._assignment, admit_date: e.target.value });
    }
  };

  let initialAssessment = {};
  _assignment?.instrument || _assignment?.risk_level ?
    initialAssessment = {
      instrument: {
        name: _assignment?.instrument || ``,
      },
      riskLevel: {
        name: _assignment?.risk_level || ``,
      },
    } :
    initialAssessment = null;

  let initialOffense = {};
  _assignment?.offense_classification || _assignment?.offense_description ?
    initialOffense = {
      classification: {
        name: _assignment?.offense_classification || ``,
      },
      description: {
        name: _assignment?.offense_description || ``,
      },
    } :
    initialOffense = null;

  const assessment = watch(`assessment`, initialAssessment ? JSON.stringify(initialAssessment) : null);
  const offense = watch(`offense`, initialOffense ? JSON.stringify(initialOffense) : null);

  const onSubmit = async data => {
    if (!defaultReleaseReason) {
      data.release_reason_id = null;
    }
    if (!defaultOutcomeAchieve) {
      data.outcome_achieved = null;
    }

    if (data.referral_date) {
      data.referral_date = dayjs(data.referral_date).set(`hour`, 12);
    }
    data.admission_date = dayjs(data.admission_date).set(`hour`, 12);
    if (data.release_date) {
      data.release_date = dayjs(data.release_date).set(`hour`, 12);
    }

    data.referral_date = data.referral_date === `` ? null : data.referral_date;
    data.admit_date = data.admit_date === `` ? null : data.admit_date;
    data.release_date = data.release_date === `` ? null : data.release_date;
    data.hours_completed = data.hours_completed === `` ? null : data.hours_completed;
    data.family_hours = data.family_hours === `` ? null : data.family_hours;

    if (data.release_date && !data.release_reason_id) {
      handleError(new Error(`Cannot provide a release date without a valid reason`));
    }

    const newAssignment = { ..._assignment, ...data };

    delete newAssignment.program;
    delete newAssignment.releaseReason;
    delete newAssignment.fundingSource;
    delete newAssignment.offender;

    await processAssignment(newAssignment, _offender.id);
    reset(_assignment, _offender);
  };

  useEffect(() => {
    const fetchData = async () => {
      const outcomes = await ProgramService.getOutcomeAchievedOptions();

      const formatted_outcomes = outcomes.map(({ id, name }) => ({ label: name, value: id }));
      setOutcomeAchieved(formatted_outcomes);
      const _default = formatted_outcomes.find(outcomeAchieve => outcomeAchieve.value === _assignment.outcome_achieved);
      setDefaultOutcomeAchieve(_default);
    };
    fetchData();
  }, [ _assignment.outcome_achieved ]);

  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (err) {

      return false;
    }

    return true;
  };

  return (
    <Modal aria-labelledby="msg" data-testid="updateProgram" show={isModalShown} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title id="msg" name="msg">Update Program Assignment</Modal.Title>
        <CloseButton data-testid="closeBtn" onClick={onClose} />
      </Modal.Header>
      <PromiseForm onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group" data-testid="offender">
                <label id="lbl_offender" htmlFor="offender" class="required-field">
                  {offender_term}:
                </label>
                <AsyncSelect
                  {...register(`offender`,
                    {
                      required: `Offender is required`,
                      value: offender,
                    })}
                  backspaceRemovesValue
                  classNamePrefix="react-select"
                  name="offender"
                  id="offender"
                  inputId="offender"
                  aria-labelledby="lbl_offender"
                  placeholder="Search..."
                  loadOptions={debounce(searchOffenders, 300, { leading: true })}
                  defaultValue={
                    _offender &&
                  {
                    label: `${_offender.first_name} ${_offender.last_name}`,
                    value: _offender,
                  }
                  }
                  components={{ Option: OffenderOption }}
                  onChange={handleSelection}
                  isDisabled={!!offender}
                />
                <InlineError errors={errors} name="offender" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" data-testid="program">
                <label id="lbl_program" htmlFor="program" class="required-field">
                  Program:
                </label>
                <input
                  name="program"
                  id="program"
                  aria-labelledby="lbl_program"
                  type="text"
                  defaultValue={program.name}
                  className="form-control"
                  disabled={true} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  id="lbl_referral_date"
                  htmlFor="referral_date">
                  Referral Date:
                </label>
                <input
                  {...register(`referral_date`,
                    { value: _assignment?.referral_date })}
                  type="date"
                  name="referral_date"
                  id="referral_date"
                  data-testid="referral_date"
                  aria-labelledby="lbl_referral_date"
                  className="form-control"
                  value={_assignment?.referral_date}
                  max={dayjs().format(`YYYY-MM-DD`)}
                  onChange={handleReferralDate}
                  aria-describedby="dateFormat"
                />
                <span id="dateFormat" className="sr-only">mm/dd/yyyy</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  id="lbl_admit_date"
                  htmlFor="admit_date">
                  Admission Date:
                </label>
                <input
                  {...register(`admit_date`,
                    { value: _assignment?.admit_date })}
                  type="date"
                  name="admit_date"
                  id="admit_date"
                  data-testid="admit_date"
                  aria-labelledby="lbl_admit_date"
                  className="form-control"
                  value={_assignment?.admit_date}
                  max={dayjs().format(`YYYY-MM-DD`)}
                  onChange={handleAdmissionDate}
                  aria-describedby="dateFormat"
                />
                <InlineError errors={errors} name="admit_date" />
                <span id="dateFormat" className="sr-only">mm/dd/yyyy</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  id="lbl_release_date"
                  htmlFor="release_date">
                  Program Release Date:
                </label>
                <input
                  {...register(`release_date`,
                    { value: _assignment?.release_date })}
                  type="date"
                  name="release_date"
                  id="release_date"
                  data-testid="release_date"
                  aria-labelledby="lbl_release_date"
                  className="form-control"
                  value={_assignment?.release_date}
                  max={dayjs().format(`YYYY-MM-DD`)}
                  onChange={handleReleaseDate}
                  aria-describedby="dateFormat"
                />
                <span id="dateFormat" className="sr-only">mm/dd/yyyy</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group" data-testid="release_reason">
                <label id="lbl_release_reason_id" htmlFor="release_reason_id">
                  Program Release Reason:
                  <div className="d-inline-block mx-2 my-n2" data-testid="releaseHelpBtn">
                    <ModulePopover
                      title="Release Reason Key"
                      body="(U)=Unsuccessful (N)=Neutral (S)=Successful"
                      placement="top"
                    />
                  </div>
                </label>
                {
                  <Select
                    classNamePrefix="react-select"
                    name="release_reason_id"
                    id="release_reason_id"
                    inputId="release_reason_id"
                    aria-labelledby="lbl_release_reason_id"
                    isDisabled={!_assignment.release_date}
                    value={defaultReleaseReason}
                    options={releaseReasons}
                    onChange={handleSelection}
                    isClearable
                  />
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  id="lbl_hours_completed"
                  htmlFor="hours_completed">
                  {offender_term} Hours
                </label>
                <input
                  {...register(`hours_completed`)}
                  type="number"
                  name="hours_completed"
                  id="hours_completed"
                  data-testid="hours_completed"
                  aria-labelledby="lbl_hours_completed"
                  className="form-control"
                  minLength="0" maxLength="5"
                  disabled={!_assignment.release_date}
                />
              </div>
            </div>
            {
              _offender && _offender.is_youth ? <div className="col-md-6">
                <div className="form-group">
                  <label
                    id="lbl_family_hours"
                    htmlFor="family_hours">
                    Family Hours
                  </label>
                  <input
                    {...register(`family_hours`)}
                    type="number"
                    name="family_hours"
                    id="family_hours"
                    data-testid="family_hours"
                    aria-labelledby="lbl_family_hours"
                    className="form-control"
                    minLength="0" maxLength="5"
                    disabled={!_assignment.release_date}
                  />
                </div>
              </div> : null
            }
            <div className="col-md-6">
              <div className="form-group" data-testid="outcome_achieved">
                <label
                  id="lbl_outcome_achieved"
                  htmlFor="outcome_achieved">
                  Primary Outcome Achieved?
                </label>
                {
                  <Select
                    classNamePrefix="react-select"
                    name="outcome_achieved"
                    id="outcome_achieved"
                    inputId="outcome_achieved"
                    isDisabled={!_assignment.release_date}
                    value={defaultOutcomeAchieve}
                    options={outcomeAchieved}
                    onChange={handleSelection}
                    isClearable
                  />
                }
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" data-testid="assessmentSelection">
                <label
                  id="lbl_assessment"
                  htmlFor="assessment">
                  Select an Assessment:
                </label>
                <Select
                  classNamePrefix="react-select"
                  name="assessment"
                  id="assessment"
                  inputId="assessment"
                  aria-labelledby="lbl_assessment"
                  options={
                    assessments.map(a => ({
                      label: `Instrument: ${a.instrument.name}, 
                            Risk Level: ${a.riskLevel.name}, 
                            Override Risk Level: ${a.override_risk_text}, 
                            Created At: ${dayjs(a.created_at).format(`YYYY-MM-DD`)}`,
                      value: a,
                    }))
                  }
                  value={assessment?.created_at ? {
                    label: `Instrument: ${assessment.instrument.name},
                          Risk Level: ${assessment.riskLevel.name},
                          Override Risk Level: ${assessment.override_risk_text},
                          Created At: ${dayjs(assessment.created_at).format(`YYYY-MM-DD`)}`,
                    value: assessment,
                  } : null}
                  onChange={handleSelection}
                  isClearable
                />
              </div>
              {
                assessment ?
                  <dl className="col-md-6">
                    <div id="assessment_instrument" aria-labelledby="lbl_assessment_instrument" className="row">
                      <dt
                        id="lbl_assessment_instrument"
                        data-testid="assessment_instrument"
                        htmlFor="assessment_instrument">
                        Assessment Instrument: &nbsp;
                      </dt>
                      <dd>
                        {assessment?.instrument?.name ? assessment?.instrument?.name :
                          IsJsonString(assessment) ? JSON.parse(assessment)?.instrument?.name : ``}
                      </dd>
                    </div>
                    <div
                      id="assessment_risk"
                      data-testid="assessment_risk"
                      aria-labelledby="lbl_assessment_risk"
                      className="row"
                    >
                      <dt
                        id="lbl_assessment_risk"
                        htmlFor="assessment_risk">Assessment Risk Level: &nbsp;
                      </dt>
                      <dd>
                        {assessment?.riskLevel?.name ? assessment?.riskLevel?.name :
                          IsJsonString(assessment) ? JSON.parse(assessment)?.riskLevel?.name : ``}
                      </dd>
                    </div>
                  </dl> :
                  ``
              }
            </div>
            <div className="col-md-12">
              <div className="form-group" data-testid="offenseSelection">
                <label id="lbl_offense" htmlFor="offense">
                  Select an Offense:
                </label>
                <Select
                  classNamePrefix="react-select"
                  name="offense"
                  id="offense"
                  inputId="offense"
                  aria-labelledby="lbl_offense"
                  options={
                    offenses.map(o => ({
                      label: `Code: ${o.offenseCode.code}, 
                            Classification: ${o.classification ? o.classification.name : ``}, 
                            Description: ${o.description ? o.description.name : ``}, 
                            Arrest Date: ${o.arrest_date}`,
                      value: o,
                    }))
                  }
                  value={offense?.arrest_date ? {
                    label: `Code: ${offense?.offenseCode?.code},
                          Classification: ${offense.classification ? offense.classification?.name : ``},
                          Description: ${offense.description ? offense.description.name : ``},
                          Arrest Date: ${offense.arrest_date}`,
                    value: offense,
                  } : null}
                  onChange={handleSelection}
                  isClearable
                />
              </div>
              {
                !!offense &&
                  <dl className="col-md-6">
                    <div className="row">
                      <dt
                        id="lbl_offense"
                        htmlFor="offense">
                        Offense Classification: &nbsp;
                      </dt>
                      <dd
                        id="offense"
                        name="offense"
                        data-testid="offense"
                        aria-labelledby="lbl_offense"> {offense?.classification?.name ? offense?.classification?.name :
                          IsJsonString(offense) ? JSON.parse(offense)?.classification?.name : ``}
                      </dd>
                    </div>
                    <div className="row">
                      <dt
                        id="lbl_description"
                        htmlFor="description">
                        Offense Description: &nbsp;
                      </dt>
                      <dd
                        id="description"
                        name="description"
                        data-testid="description"
                        aria-labelledby="lbl_description"> {offense?.description?.name ? offense?.description?.name :
                          IsJsonString(offense) ? JSON.parse(offense)?.description?.name : ``}
                      </dd>
                    </div>
                  </dl>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-custom" type="submit" data-testid="ProgramSave">Save</button>
        </Modal.Footer>
      </PromiseForm>
    </Modal>
  );
};

UpdateProgramAssignment.propTypes = {
  assignment: PropTypes.object,
  isModalShown: PropTypes.bool,
  offender: PropTypes.object,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  processAssignment: PropTypes.func,
};

const OffenderOption = (props) => <components.Option {...props} />;
