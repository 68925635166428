import RA_LOGO_BLUE from 'assets/images/RA_LOGO_BLUE.png';
import { v4 as uuidv4 } from 'uuid';
import { ErrorResponse } from '../utils/http.config';

export const FullPageErrorFallback: React.FC<{ errors: Array<ErrorResponse | undefined>, height?: string }> =
 ({ errors, height = `100%` }) =>
   <div
     role="alert"
     style={{
       alignItems: `center`,
       display: `flex`,
       flexDirection: `column`,
       height,
       justifyContent: `center`,
       textAlign: `center`,
       width: `100%`,
     }}
   >
     <div style={{ width: `500px` }}>
       <img style={{ height: `250px`, width: `auto` }} src={RA_LOGO_BLUE} alt="Risk Assessment System Logo" />
       <br /><br />
       <p>
         An error has occurred while trying to  reach the Risk Assessment
         System. Please check your Internet connection to make
         sure you are able to access the system. Please use this <a href="/">link</a> to return to the application home.
         If the problem persists please contact the Information Technology Solutions Center:<br /><br />
         <label htmlFor="phone">Phone: </label>&nbsp;
         <a id="phone" href="tel:+15135565027">(513) 556 - 5027 </a> <br />
         <label htmlFor="email">Email:</label>&nbsp;
         <a id="email" href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL || ``}`}>
           {process.env.REACT_APP_CONTACT_EMAIL}
         </a>
         <br />
         <label htmlFor="ticketing">Ticketing System:</label>&nbsp;
         <a
           id="ticketing"
           href={process.env.REACT_APP_JIRA_TICKET_URL}
           target="_blank"
           rel="noopener noreferrer">ITSC Help</a><br />
         <label htmlFor="hours">Hours:</label> Monday - Friday: 9AM - 5PM EST
       </p>
     </div>
     {
       errors?.map((e) =>
         e &&
           <pre key={uuidv4()}>
             {e.response?.status} - {e.response?.data?.message}
           </pre>)
     }
     <footer style={{
       bottom: 0,
       color: `#939597`,
       fontFamily: `'Open Sans', sans serif`,
       fontSize: `1.5rem`,
       fontWeight: 700,
       paddingTop: `0.5rem`,
       position: `fixed`,
       textAlign: `center`,
       textTransform: `uppercase`,
       width: `100%`,
     }}>
       <div className="row">
         <div className="col">
           University of Cincinnati Corrections Institute<br />
           <span className="sub-text">Information Technology Solutions Center - University of Cincinnati &copy; <span
             id="date" />{new Date().getFullYear()}</span>
         </div>
       </div>
     </footer>
   </div>;
