import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { IoMdLock as LockedIcon } from 'react-icons/io';
import PropTypes from 'prop-types';

const lockedTooltip = (props) =>
  <Tooltip id="locked-tooltip" className="tooltip-no-arrow" {...props}>
    <h6>{`Assessment is Locked`}</h6>
  </Tooltip>;

export const LockedIndicator = () =>
  <IconContext.Provider value={{ color: `#df1f26` }}>
    <OverlayTrigger
      placement="right"
      overlay={lockedTooltip}>
      <LockedIcon />
    </OverlayTrigger>
  </IconContext.Provider>;

lockedTooltip.propTypes = {
  props: PropTypes.object,
};
