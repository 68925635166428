import { Axios } from 'shared/utils';

export class InstrumentService {
  static async getInstrumentList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/instrument/list?all=${all}`,
      });

      return response.data.data.instruments;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateReassessmentPeriod(instrument_id, length) {
    try {
      const response = await Axios({
        data: {
          length,
        },
        method: `PUT`,
        url: `/instrument/${instrument_id}/reassessment-period`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateReassessmentOnLowRisk(instrument_id, reassessment) {
    try {
      const response = await Axios({
        data: {
          reassessment,
        },
        method: `PUT`,
        url: `/instrument/reassessment_low_risk/${instrument_id}`,
      });

      return response.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async activateInstrument(instrument_id) {
    try {
      await Axios({
        data: {
          instrument_id,
        },
        method: `PUT`,
        url: `/instrument/activate`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deactivateInstrument(instrument_id) {
    try {
      await Axios({
        data: {
          instrument_id,
        },
        method: `PUT`,
        url: `/instrument/deactivate`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOutcomesForInstrument(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/instrument/${id}/outcomes`,
      });

      return response.data.data.outcomes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
