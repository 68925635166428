import React from 'react';
import { AsyncStatus } from '@react-hook/async';
import { FullPageErrorFallback } from './FullPageErrorFallback';
import { Lottie } from './Lottie';

export const AsyncPageWrapper: React.FC<
{ status: AsyncStatus, error?: Error, height?: string } |
{ status: AsyncStatus[], error: Array<Error | undefined>, height?: string }
> = ({ children, error, height, status }) => {
  if (Array.isArray(status)) {
    if (status.includes(`loading`) || status.includes(`idle`)) {
      return <Lottie lottie="ra" />;
    }
    else if (status.includes(`error`)) {
      return <FullPageErrorFallback height={height} errors={error as any} />;
    }
    else if (status.every(s => s === `success`)) {
      return <>{children}</>;
    }
    throw new Error(`Unhandled status: ${status.join(`, `)}`);
  } else {
    switch (status) {
      case `loading`:
      case `idle`:
        return <Lottie lottie="ra" />;
      case `error`:
        return <FullPageErrorFallback height={height} errors={[ error as any ]} />;
      case `success`:
        return <>{children}</>;
      default:
        throw new Error(`Unhandled status: ${status}`);
    }
  }
};
