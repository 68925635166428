import { Axios } from 'shared/utils';

export class FileService {
  static async getCategories(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/file/share/category/list`,
      });

      return response.data.data.categories;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createCategory(category) {
    try {
      const response = await Axios({
        data: { category },
        method: `POST`,
        url: `/file/share/category/new`,
      });

      return response.data.data.category;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateCategory(id, category) {
    try {
      await Axios({
        data: { category },
        method: `PUT`,
        url: `/file/share/category/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteCategory(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/file/share/category/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreCategory(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/file/share/category/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getSharedFiles() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/file/share`,
      });

      return response.data.data.shares;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async upload(data) {
    try {
      const response = await Axios({
        data,
        method: `POST`,
        url: `/file/share`,
      });

      return response.data.data.file;
    }
    catch (err) {
      if (err.response.status === 413) {

        throw new Error(`File size exceeds upload limit`);
      }
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async editSharedFile(id, file) {
    try {
      await Axios({
        data: { file },
        method: `PUT`,
        url: `/file/share/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deletedShareFile(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/file/share/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
