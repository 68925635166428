import qs from 'query-string';
import { Axios } from 'shared/utils';

export class ProgramService {
  static getMapData() {
    return Axios({
      method: `GET`,
      url: `/program/map`,
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`Failed to get program list`);
        }

        return response.data.data.programs;
      });
  }

  static async getProgramsAfterMerge(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/program/afterMerge/${id}`,
      });

      return response.data.data.programs;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryMerge(mergeInfo) {
    try {
      const response = await Axios({
        data: { mergeInfo },
        method: `POST`,
        url: `/program/merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryUnMerge(unMergeInfo) {
    try {
      const response = await Axios({
        data: { unMergeInfo },
        method: `POST`,
        url: `/program/un-merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOutcomeAchievedOptions() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/program/outcome-achieved/list`,
      });

      return response.data.data.outcomeAchievedOptions;

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async search(q, full = false, activity_purpose_id = null) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { activity_purpose_id, full, q },
        url: `/program/search`,
      });

      return response.data.data.programs;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async searchFundingSources(q) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/funding/search?q=${q}`,
      });

      return response.data.data.fundingSources;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getActivityPurposeList(all) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/program/activity-purpose/list`,
      });

      return response.data.data.activityPurposes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createActivityPurpose(activityPurpose) {
    try {
      const response = await Axios({
        data: { activityPurpose },
        method: `POST`,
        url: `/program/activity-purpose/new`,
      });

      return response.data.data.activityPurpose;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateActivityPurpose(id, activityPurpose) {
    try {
      await Axios({
        data: { activityPurpose },
        method: `PUT`,
        url: `/program/activity-purpose/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteActivityPurpose(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/program/activity-purpose/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreActivityPurpose(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/program/activity-purpose/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getPrimaryInterventionList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/program/primary-intervention/list`,
      });

      return response.data.data.primaryInterventions;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createPrimaryIntervention(primaryIntervention) {
    try {
      const response = await Axios({
        data: { primaryIntervention },
        method: `POST`,
        url: `/program/primary-intervention/new`,
      });

      return response.data.data.primaryIntervention;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updatePrimaryIntervention(id, primaryIntervention) {
    try {
      await Axios({
        data: { primaryIntervention },
        method: `PUT`,
        url: `/program/primary-intervention/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deletePrimaryIntervention(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/program/primary-intervention/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restorePrimaryIntervention(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/program/primary-intervention/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getReleaseReasonList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/program/release-reason/list`,
      });

      return response.data.data.releaseReasons;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createReleaseReason(reason) {
    try {
      const response = await Axios({
        data: { reason },
        method: `POST`,
        url: `/program/release-reason/new`,
      });

      return response.data.data.releaseReason;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateReleaseReason(id, reason) {
    try {
      await Axios({
        data: { reason },
        method: `PUT`,
        url: `/program/release-reason/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteReleaseReason(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/program/release-reason/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreReleaseReason(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/program/release-reason/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getTreatmentTypeList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/program/treatment-type/list`,
      });

      return response.data.data.treatmentTypes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createTreatmentType(treatmentType) {
    try {
      const response = await Axios({
        data: { treatmentType },
        method: `POST`,
        url: `/program/treatment-type/new`,
      });

      return response.data.data.treatmentType;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateTreatmentType(id, treatmentType) {
    try {
      const response = await Axios({
        data: { treatmentType },
        method: `PUT`,
        url: `/program/treatment-type/${id}`,
      });

      return response.data.data.treatmentType;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteTreatmentType(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/program/treatment-type/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreTreatmentType(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/program/treatment-type/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDetails(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/program/${id}`,
      });

      return response.data.data.program;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static createProgram = async (program) => {
    const response = await Axios(
      {
        data: { program },
        method: `POST`,
        url: `/program/new`,
      }
    );
    if (response.status !== 200) {
      throw new Error(`Failed to create program.`);
    }

    return response.data.data.program;
  };

  static checkForPossibleDuplicates = async (name) => {
    const response = await Axios({
      method: `GET`,
      params: { name },
      url: `/program/duplicate-check`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to find possible duplicates`);
    }

    return response.data.data.programs;
  };

  static async assign({ assignment, offender, program }) {
    try {
      const response = await Axios({
        data: { assignment },
        method: `POST`,
        url: `/program/${program}/offender/${offender}/assign`,
      });

      return response.data.data.assignment;
    }
    catch (err) {
      throw new Error(`${err.response.data.message}`);
    }
  }

  static async updateProgramAssignment(id, assignment) {
    try {
      const response = await Axios({
        data: { assignment },
        method: `PUT`,
        url: `/program/assignment/${id}`,
      });

      return response.data.data.assignment;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteAssignment(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/program/assignment/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async release(data) {
    try {
      await Axios({
        data,
        method: `POST`,
        url: `/program/offender/release`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getProgramList(page, limit, filter, all = false) {
    const response = await Axios({
      method: `GET`,
      params: { all, filter, limit, page },
      url: `/program/list`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to get program list`);
    }

    return response.data.data.programs;
  }

  static async deletePrograms(ids) {
    try {
      const response = await Axios({
        method: `DELETE`,
        url: `/program?${qs.stringify({ ids })}`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getProgramAssignment(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/program/assignment/${id}`,
      });

      return response.data.data.assignment;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getProgramAssignmentList(page, limit, filter) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { filter, limit, page },
        url: `/program/assign/list`,
      });

      return response.data.data.results;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getProgramAssignments(query) {
    try {
      const response = await Axios({
        method: `GET`,
        params: query,
        url: `/program/offender/assignment`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDomainList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/program/domain/list`,
      });

      return response.data.data.domains;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static getAssignmentsForActivityPurpose = async (filter) => {
    const response = await Axios({
      method: `GET`,
      params: { filter },
      url: `/program/assignments/activity-purpose`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to get program assignment`);
    }

    return response.data.data;
  };

  static async addAddress(program, address) {
    try {
      const response = await Axios({
        data: { address },
        method: `POST`,
        url: `/program/${program}/address/new`,
      });

      return response.data.data.address;
    } catch (err) {

      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async assignProgramAgency(id, agency_id) {
    try {

      const response = await Axios({
        data: { agency_id },
        method: `PUT`,
        url: `/program/agency/${id}`,
      });

      return response;
    } catch (err) {

      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async updateProgram(id, program) {
    try {
      const response = await Axios({
        data: { program },
        method: `PUT`,
        url: `/program/${id}`,
      });

      return response.data.data.program;
    } catch (err) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async addHours(program, hour) {
    try {
      const response = await Axios({
        data: { hour },
        method: `POST`,
        url: `/program/${program}/hour/new`,
      });

      return response.data.data.hour;
    } catch (err) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async deleteHours(program, hour) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/program/${program}/hour/${hour}`,
      });

    } catch (err) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static deleteProgram = async (id) => {
    try {
      const response = await Axios({
        method: `DELETE`,
        url: `/program/${id}`,
      });

      return response;
    } catch (err) {
      throw new Error(`Failed to delete program`);
    }
  };

  static disableProgram = async ({ id }) => {
    try {
      const response = await Axios({
        method: `PUT`,
        url: `/program/${id}/disable`,
      });

      return response;
    } catch (err) {
      throw new Error(`Failed to disable program`);
    }
  };

  static disableProgramType = async (id) => {
    const response = await Axios({
      method: `POST`,
      url: `/program/type/${id}/disable`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to disable program`);
    }

    return response.data.data;
  };

  static restoreProgram = async (id) => {
    const response = await Axios({
      method: `PUT`,
      url: `/program/${id}/restore`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to restore program`);
    }

  };

  static changeAvatar(id, data) {

    return Axios({
      data,
      method: `POST`,
      url: `/program/${id}/avatar`,
    });
  }
}
