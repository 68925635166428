import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor as TinyMceEditor } from '@tinymce/tinymce-react';

export const Editor = ({ disabled, height, id, initialText, max_height, min_height, onTextChange, plugins }) => {
  const TINY_MCE_SCRIPT_PATH = `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`;
  const editorRef = useRef(initialText);

  return (
    <TinyMceEditor
      id={id || null}
      tinymceScriptSrc={TINY_MCE_SCRIPT_PATH}
      onInit={(evt, editor) => editorRef.current = editor}
      initialValue={initialText}
      init={{
        browser_spellcheck: true,
        height,
        max_height,
        menubar: `insert format`,
        min_height,
        plugins,
        promotion: false,
      }}
      onEditorChange={(value) => onTextChange(value)}
      disabled={disabled}
    />);
};

Editor.propTypes = {
  initialText: PropTypes.string,
  onTextChange: PropTypes.func,
};
