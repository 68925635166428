import { Axios } from 'shared/utils';
import qs from 'query-string';

export class ReportingService {

  static async getOffenderReport({ map = false }) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/report/offender?map=${map}`,
      });
      if (response.status !== 200) {
        throw new Error(`Failed to get offenders`);
      }

      return response.data.data.offenders;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getFundingReport() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/report/funding`,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to get funding`);
      }

      return response.data.data.agency;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAgencyDemographicReport() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/report/agency/demographic`,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to get races`);
      }

      return response.data.data.races;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getInteractiveAssessmentReport({ override }) {
    try {
      const query = qs.stringify({
        override,
      });

      const response = await Axios({
        method: `GET`,
        url: `/report/interactive/assessment?${query}`,
      });
      if (response.status !== 200) {
        throw new Error(`Failed to get interactive assessment report`);
      }

      return response.data.data;

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getInteractiveDomainReport() {
    try {
      const response = await Axios({
        method: `POST`,
        url: `/report/interactive/domain`,
      });
      if (response.status !== 200) {
        throw new Error(`Failed to get interactive domain report`);
      }

      return response.data.data;

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getRecidivismReport({ override }) {
    try {
      const query = qs.stringify({
        override,
      });

      const response = await Axios({
        method: `GET`,
        url: `/report/recidivism/time?${query}`,
      });
      if (response.status !== 200) {
        throw new Error(`Failed to get recidivism report`);
      }

      return response.data.data;

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
