import { Axios } from 'shared/utils';

export class WebhookService {

  static fetchEvents = async () => {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/webhook/event`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  };

  static registerWebhook = async ({ events, url }) => {
    try {
      const response = await Axios({
        data: { events, url },
        method: `POST`,
        url: `/webhook`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  };

  static fetchWebhook = async id => {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/webhook/${id}`,
      });

      return response;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  };

  static fetchAllWebhooks = async () => {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/webhook`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  };

  static deleteWebhook = async id => {
    try {
      const response = await Axios({
        method: `DELETE`,
        url: `/webhook/${id}`,
      });

      return response;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  };

}
