import { capitalize } from 'lodash';

export const translate = (term, terms) => {

  const translations = {
    caseplan: terms.caseplan ? capitalize(terms.caseplan) : `Case Plan`,
    goal: terms.goal ? capitalize(terms.goal) : `Goal`,
    objective: terms.objective ? capitalize(terms.objective) : `Objective`,
    offender: terms.offender ? capitalize(terms.offender) : `Offender`,
    technique: terms.technique ? capitalize(terms.technique) : `Technique`,
  };

  return translations[term.toLowerCase()];
};
