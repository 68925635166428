import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const PromiseForm = ({ children, onSubmit, ...props }) => {
  const [ isDisabled, setDisabled ] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    if (!isDisabled) {
      try {
        setDisabled(true);
        await onSubmit(event);
      } finally {
        setDisabled(false);
      }
    }

  };

  return <form onSubmit={handleSubmit} {...props}>{children}</form>;
};

PromiseForm.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.element, PropTypes.arrayOf(PropTypes.element) ]),
  onSubmit: PropTypes.func,
};
