import React from 'react';
import PropTypes from 'prop-types';

export const Checkbox = (props) => {
  const { isIndeterminate, ...rest } = props;
  delete rest.indeterminate; // React-table sends an indeterminate property which React is throwing warnings about.

  const setCheckboxRef = checkbox => {
    if (checkbox) {
      checkbox.indeterminate = isIndeterminate;
    }
  };

  return <input type="checkbox" {...rest} ref={setCheckboxRef} />;
};

Checkbox.propTypes = {
  isIndeterminate: PropTypes.bool,
};
Checkbox.defaultProps = {
  isIndeterminate: false,
};
