import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';

export const ConfirmationModal = ({
  children,
  confirmText = `Delete`,
  confirmationQuestion,
  onConfirm,
  onReject,
  showModal,
  variant = true,
}) => {
  const [ isModalShown, setModalShown ] = useState(showModal);

  useEffect(() => {
    setModalShown(showModal);
  }, [ showModal ]);

  return (
    <Modal
      data-testid="modal"
      aria-labelledby="msg"
      show={isModalShown}
      className={ variant && `modal-variant`}
      onHide={
        () => {
          onReject();
          setModalShown(false);
        }
      }>
      <Modal.Header>
        <Modal.Title id="msg" name="msg" data-testid="confirmTitle">{confirmationQuestion}</Modal.Title>
        <CloseButton data-testid="noBtn" onClick={onReject} />
      </Modal.Header>
      { children ? <Modal.Body>{children}</Modal.Body> : null }
      <Modal.Footer>
        <button className="btn btn-danger" onClick={onConfirm} data-testid="yesBtn">{ confirmText }</button>
      </Modal.Footer>
    </Modal>
  );
};
ConfirmationModal.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.element, PropTypes.arrayOf(PropTypes.element) ]),
  confirmationQuestion: PropTypes.string,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  showModal: PropTypes.bool,
};
