import { Axios } from 'shared/utils';

export class AgencyService {
  static async search(query, getEntireOrganization = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: {
          getEntireOrganization,
          q: query,
        },
        url: `/agency/search`,
      });

      return response.data.data.agencies;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createAgency(agency) {
    try {
      const response = await Axios({
        data: { agency },
        method: `POST`,
        url: `/agency/new`,
      });

      return response.data.data.agency;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDetails(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/agency/${id}/info`,
      });

      return response.data.data.agency;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateAgency(id, agency) {
    try {
      await Axios({
        data: { agency },
        method: `PUT`,
        url: `/agency/${id}`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async delete(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/agency/${id}`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restore(id) {
    try {
      const response = await Axios({
        method: `POST`,
        url: `/agency/${id}/restore`,
      });

      return response.data.data.agency;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async list(filter, limit, page) {
    try {
      const response = await Axios({
        data: {
          options: {
            filter,
            limit,
            page,
          },
        },
        method: `POST`,
        url: `/agency/list`,
      });

      return response.data.data.agencies;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
