import { Axios } from 'shared/utils';

export class OffenseService {
  static async getOffenseCodes(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/offense/code/list`,
      });

      return response.data.data.offenseCodes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createOffenseCode(code) {
    try {
      const response = await Axios({
        data: { code },
        method: `POST`,
        url: `/offense/code/new`,
      });

      return response;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateOffenseCode(id, code) {
    try {
      await Axios({
        data: { code },
        method: `POST`,
        url: `/offense/code/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteOffenseCode(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offense/code/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreOffenseCode(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/offense/code/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffenseLevelList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/offense/level/list`,
      });

      return response.data.data.offenseLevels;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createOffenseLevel(level) {
    try {
      const response = await Axios({
        data: { level },
        method: `POST`,
        url: `/offense/level/new`,
      });

      return response.data.data.offenseLevel;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateOffenseLevel(id, level) {
    try {
      await Axios({
        data: { level },
        method: `PUT`,
        url: `/offense/level/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryMerge(mergeInfo) {
    try {
      const response = await Axios({
        data: { mergeInfo },
        method: `POST`,
        url: `/offense/merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryUnMerge(unMergeInfo) {
    try {
      const response = await Axios({
        data: { unMergeInfo },
        method: `POST`,
        url: `/offense/un-merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteOffenseLevel(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offense/level/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreOffenseLevel(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/offense/level/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffenseTypeList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/offense/type/list`,
      });

      return response.data.data.offenseTypes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createOffenseType(offenseType) {
    try {
      const response = await Axios({
        data: { offenseType },
        method: `POST`,
        url: `/offense/type/new`,
      });

      return response.data.data.offenseType;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateOffenseType(id, offenseType) {
    try {
      const response = await Axios({
        data: { offenseType },
        method: `PUT`,
        url: `/offense/type/${id}`,
      });

      return response.data.data.offenseType;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteOffenseType(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offense/type/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreOffenseType(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/offense/type/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteOffense(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offense/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateOffense(id, offense) {
    try {
      const response = await Axios({
        data: {
          offense,
        },
        method: `PUT`,
        url: `/offense/${id}`,
      });

      return response.data.data.offense;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async searchOffenseCodes(q) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offense/code/search?q=${q}`,
      });

      return response.data.data.offenseCodes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffenseCreateData() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offense/new/data`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createOffense(offender, offense) {
    try {
      const response = await Axios({
        data: { offense },
        method: `POST`,
        url: `/offender/${offender}/offense/new`,
      });

      return response.data.data.offense;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffenseInfo(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${id}/offense`,
      });

      return response.data.data.offenses;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffensesAfterMerge(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offense/afterMerge/${id}`,
      });

      return response.data.data.offenses;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
