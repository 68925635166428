import { Axios } from 'shared/utils';
import objectPath from 'object-path';

export class ConfigService {

  static config = {};

  static async updateLocalConfig() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/config`,
      });

      this.config = response.data.data.config || {};
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateAgencyConfig(config) {
    try {
      const response = await Axios({
        data: { config },
        method: `PUT`,
        url: `/user/agency/config`,
      });

      this.config = response.data.data.config || {};
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAdminConfig() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/config?admin=true`,
      });

      return {
        config: response.data.data.config || {},
        isAbsolute: response.data.data.isAbsolute || false,
      };
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static get(key, fallback) {
    return objectPath.get(this.config, key, fallback || { value: null }).value;
  }

  static getConfig() { return this.config; }

  static async getFeatureFlags() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/config/feature-flags`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
