import React from 'react';
import PropTypes from 'prop-types';

export const IndeterminateCheckbox = ({ actionName, defaultChecked, indeterminate, onClick }) =>
  <input
    type="checkbox"
    id={`${actionName}`}
    aria-labelledby={`lbl_${actionName}`}
    defaultChecked={defaultChecked}
    ref={checkbox => checkbox && (checkbox.indeterminate = indeterminate)}
    onClick={onClick}
  />;

IndeterminateCheckbox.propTypes = {
  actionName: PropTypes.string,
  defaultChecked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onClick: PropTypes.func,
};
