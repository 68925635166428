import { Axios } from 'shared/utils';
import { dayjs } from 'shared/utils';

export class CasePlanService {
  static async getCasePlanList({ filter: _filter, limit, page }) {
    try {
      const filter = { ..._filter };
      [ `created_at`, `updated_at`, `completed_at` ].forEach(field => {
        if (filter[field]) {
          const date = dayjs(filter[field]).set(`hour`, 0);
          filter[field] = date;
        }
      });

      const response = await Axios({
        method: `GET`,
        params: { filter, limit, page },
        url: `/caseplan/list`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getCasePlansAfterMerge(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/caseplan/afterMerge/${id}`,
      });

      return response.data.data.caseplans;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryMerge(mergeInfo) {
    try {
      const response = await Axios({
        data: { mergeInfo },
        method: `POST`,
        url: `/caseplan/merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryUnMerge(unMergeInfo) {
    try {
      const response = await Axios({
        data: { unMergeInfo },
        method: `POST`,
        url: `/caseplan/un-merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDetails(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/caseplan/${id}`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteCaseplan(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async complete(id) {
    try {
      await Axios({
        method: `POST`,
        url: `/caseplan/${id}/complete`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async reopen(id) {
    try {
      await Axios({
        method: `POST`,
        url: `/caseplan/${id}/reopen`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async adjustPriority(caseplanId, needId, priorityId) {
    try {
      await Axios({
        data: { priorityId },
        method: `PUT`,
        url: `/caseplan/${caseplanId}/need/${needId}/priority`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createCustomDomain(id, domain, offender) {
    try {
      const response = await Axios({
        data: { domain, offender },
        method: `POST`,
        url: `/caseplan/${id}/domain`,
      });

      return response.data.data.need;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteCustomDomain(id, need, caseplan_id) {
    try {
      await Axios({
        method: `DELETE`,
        params: { caseplan_id, need },
        url: `/caseplan/${id}/domain`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getProgressList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/caseplan/objective/progress/list`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getFrequencyList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/caseplan/objective/frequency/list`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createFrequency(frequency) {
    try {
      const response = await Axios({
        data: { frequency },
        method: `POST`,
        url: `/caseplan/objective/frequency/new`,
      });

      return response.data.data.frequency;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateFrequency(id, frequency) {
    try {
      const response = await Axios({
        data: { frequency },
        method: `PUT`,
        url: `/caseplan/objective/frequency/${id}`,
      });

      return response.data.data.frequency;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteFrequency(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/objective/frequency/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreFrequency(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/caseplan/objective/frequency/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addGoal(caseplanId, needId, goal) {
    try {
      const response = await Axios({
        data: { goal },
        method: `POST`,
        url: `/caseplan/${caseplanId}/need/${needId}/goal/new`,
      });

      return response.data.data.goal;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateGoal(caseplanId, needId, goalId, goal) {
    try {
      const response = await Axios({
        data: { goal },
        method: `PUT`,
        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}`,
      });

      return response.data.data.goal;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteGoal(caseplanId, needId, goalId) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addObj(caseplanId, needId, goalId, obj) {
    try {
      const response = await Axios({
        data: { obj },
        method: `POST`,
        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}/objective/new`,
      });

      return response.data.data.objective;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateObj(caseplanId, needId, goalId, objId, obj) {
    try {
      const response = await Axios({
        data: { obj },
        method: `PUT`,
        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}/objective/${objId}`,
      });

      return response.data.data.objective;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteObj(caseplanId, needId, goalId, objId) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}/objective/${objId}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addTechnique(caseplanId, needId, goalId, objId, technique) {
    try {
      const response = await Axios({
        data: { technique },
        method: `POST`,
        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}/objective/${objId}/technique/new`,
      });

      return response.data.data.technique;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateTechnique(caseplanId, needId, goalId, objId, techniqueId, technique) {
    try {
      const response = await Axios({
        data: { technique },
        method: `PUT`,

        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}/objective/${objId}/technique/${techniqueId}`,
      });

      return response.data.data.technique;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteTechnique(caseplanId, needId, goalId, objId, techniqueId, assignment) {
    try {
      await Axios({
        method: `DELETE`,
        params: { assignment },

        url: `/caseplan/${caseplanId}/need/${needId}/goal/${goalId}/objective/${objId}/technique/${techniqueId}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getTargetList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/caseplan/target/list`,
      });

      return response.data.data.targets;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDefaultGoals(all, target_id) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all, target_id },
        url: `/caseplan/goal/default/list`,
      });

      return response.data.data.defaultGoals;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createDefaultGoal(obj) {
    try {
      const response = await Axios({
        data: { obj },
        method: `POST`,
        url: `/caseplan/goal/default/new`,
      });

      return response.data.data.defaultGoal;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateDefaultGoal(id, obj) {
    try {
      const response = await Axios({
        data: {
          obj,
        },
        method: `PUT`,
        url: `/caseplan/goal/default/${id}`,
      });

      return response.data.data.defaultGoal;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteDefaultGoal(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/goal/default/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreDefaultGoal(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/caseplan/goal/default/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDefaultObjectives(all, default_goal_id) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all, default_goal_id },
        url: `/caseplan/objective/default/list`,
      });

      return response.data.data.defaultObjectives;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createDefaultObjective(obj) {
    try {
      const response = await Axios({
        data: { obj },
        method: `POST`,
        url: `/caseplan/objective/default/new`,
      });

      return response.data.data.defaultObjective;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateDefaultObjective(id, obj) {
    try {
      await Axios({
        data: { obj },
        method: `PUT`,
        url: `/caseplan/objective/default/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteDefaultObjective(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/objective/default/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreDefaultObjective(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/caseplan/objective/default/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getTechniqueTypes(all) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/caseplan/technique/type/list`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createTechniqueType(obj) {
    try {
      const response = await Axios({
        data: { obj },
        method: `POST`,
        url: `/caseplan/technique/type/new`,
      });

      return response.data.data.techniqueType;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateTechniqueType(id, obj) {
    try {
      await Axios({
        data: { obj },
        method: `PUT`,
        url: `/caseplan/technique/type/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteTechniqueType(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/technique/type/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreTechniqueType(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/caseplan/technique/type/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getCasePlanIVEForms(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/caseplan/${id}/ive`,
      });

      return response.data.data.forms;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getCasePlanIVEFormData() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/caseplan/ive/data`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createIVEForm(id, form) {
    try {
      const response = await Axios({
        data: { form },
        method: `POST`,
        url: `/caseplan/${id}/ive`,
      });

      return response.data.data.form;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateIVEForm(id, form) {
    try {
      const response = await Axios({
        data: { form },
        method: `PUT`,
        url: `/caseplan/${id}/ive/${form.id}`,
      });

      return response.data.data.form;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteIVEForm(id, form) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/${id}/ive/${form}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addProvider(id, ive, provider) {
    try {
      const response = await Axios({
        data: { provider },
        method: `POST`,
        url: `/caseplan/${id}/ive/${ive}/provider`,
      });

      return response.data.data.provider;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteProvider(id, ive, providerId) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/${id}/ive/${ive}/provider/${providerId}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addMedication(id, ive, medication) {
    try {
      const response = await Axios({
        data: { medication },
        method: `POST`,
        url: `/caseplan/${id}/ive/${ive}/medication`,
      });

      return response.data.data.medication;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteMedication(id, ive, medication) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/caseplan/${id}/ive/${ive}/medication/${medication}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
/*
export interface Caseplan {
  id: number,
  offender_id: number,
  date_completed: string | null,
  createdBy: any,
  instrument: any,
  offender: any,
  needs: Need[],
  assessments: any[],
  riskLevels: RiskLevel[],
}

export interface RiskLevel {
  id: number,
  instrument_id: number,
  min: number,
  max: number,
  risk_level_id: number,
  gender_id: number,
  riskLevel: { id: number, name: string }
}

export interface Priority {
  id: number,
  name: string,
  needs: Need[]
}

export interface Need {
  id: number,
  risk_level_id: number,
  priority_id: number,
  case_plan_id: number,
  domain_id: number,
  orl_text: string,
  domain: Domain,
}

export interface Domain {
  id: number,
  name: string,
  targets: Target[]
}

export interface Target {
  id: number,
  name: string,
  domain_id: number,
  questions: Question[],
  goals: Goal[]
}

export interface Question {
  id: number,
  text: string,
  question_type_id: number,
  help_text: string,
  type: any,
  responses: Array<any>
}

export interface Goal {
  id: number,
  text: string,
  target_id: number,
  case_plan_need_id: number,
  objectives: Objective[],
  deleted_at: any
}

export interface Objective {
  id: number,
  text: string,
  frequency_id: number,
  case_plan_need_goal_id: number,
  offender_program_assignment_id: number,
  start_date: string,
  planned_end_date: string,
  end_date: string,
  frequency: any,
  progressHistory: any[],
  personsResponsible: any[],
  techniques: Technique[]
}

export interface Technique {
  id: number,
  text: string,
  technique_type_id: number,
  case_plan_need_goal_objective_id: number,
  offender_program_assignment_id: number,
  technique_detail: any,
  admit_date: string,
  techniqueType: any,
  program: any,
  programAssignment: any,
  progressHistory: any[],
  personsResponsible: any[],
  techniques: Technique[]
} */
