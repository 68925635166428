import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const PromiseButton = ({ children, onClick, ...props }) => {
  const [ isDisabled, setDisabled ] = useState(false);

  const handleClick = async e => {
    try {
      setDisabled(true);
      await onClick(e);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <button onClick={handleClick} disabled={isDisabled} {...props}>
      {children}
    </button>
  );
};

PromiseButton.propTypes = {
  as: PropTypes.element,
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
  onClick: PropTypes.func,
};
