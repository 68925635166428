import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';
import { handleError } from 'shared/A-UI';

interface ApiError {
  error: string;
  message: string;
  statusCode: number;
}

export type ErrorResponse = AxiosError<ApiError>;

export const Axios = axios.create({
  baseURL: `/api`,
});

Axios.interceptors.response.use(res => res, (err: ErrorResponse) => {
  if (err?.response?.data?.message === `Invalid or no session` ||
  err?.response?.data?.message === `Invalid token provided`) {
    const cookies = new Cookies();
    cookies.remove(`spa_token`);
    window.location.href = `/login`;
  }

  if (err?.code === `ERR_NETWORK`) {
    if (navigator.onLine) {
      // eslint-disable-next-line max-len
      handleError(new Error(`Uh oh.  It seems like we're having some trouble behind-the-scenes.  Please try again in a few minutes.`));
    }
    else {
      handleError(new Error(`Network error. Please check your Internet connection`));
    }
  }

  return Promise.reject(err);
});
