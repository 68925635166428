import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OffenderService } from 'shared/services';
import { handleError } from 'shared/A-UI';
import { useAuth } from './authContext';

const sortCaseload = c => c.sort((a, b) => {
  const textA = a.last_name.toUpperCase();
  const textB = b.last_name.toUpperCase();

  return textA < textB ? -1 : textA > textB ? 1 : 0;
});

export const CaseloadContext = createContext({
  addOffenderWorker: () => {},
  caseload: [],
  failedToLoad: false,
  offenderAvatarUpdate: () => {},
  offenderCreated: () => {},
  reloadCaseload: () => {},
  removeOffenderWorker: () => {},
});
CaseloadContext.displayName = `CaseloadContext`;

export const CaseloadProvider = ({ children }) => {
  const [ caseload, setCaseload ] = useState([]);
  const [ failedToLoad, setFailedToLoad ] = useState(false);
  const { info } = useAuth();

  const reloadCaseload = async () => {
    try {
      setFailedToLoad(false);
      const load = await OffenderService.getCaseload();
      setCaseload(
        sortCaseload(
          load
            .filter(a => !a.offender.deleted_at)
            .sort((a, b) => a.offender.last_name - b.offender.last_name)
            .map(a => a.offender)
        )
      );
    } catch (err) {
      setFailedToLoad(true);
      handleError(err);
    }
  };

  const offenderCreated = offender => {
    setCaseload(load => sortCaseload([ ...load, offender ]));
  };

  const addOffenderWorker = data => {
    if (data.user_id === info.id) {
      if (caseload.find(offender => offender.id === data.offender.id)) {
        return; // Offender already in caseload
      }

      setCaseload(load => sortCaseload([ ...load, data.offender ]));
    }
  };

  const offenderAvatarUpdate = data => {
    setCaseload(load => load.map(offender => {
      if (data.id === offender.id) {
        offender.avatar = data.avatar;
      }

      return offender;
    }));
  };

  const removeOffenderWorker = data => {
    if (data.user_id === info.id) {
      setCaseload(load => load.filter(offender => offender.id !== data.offender.id));
    }
  };

  useEffect(() => {
    reloadCaseload();
  }, []);

  const contextValue = {
    addOffenderWorker,
    caseload,
    failedToLoad,
    offenderAvatarUpdate,
    offenderCreated,
    reloadCaseload,
    removeOffenderWorker,
  };

  return <CaseloadContext.Provider value={contextValue}>{children}</CaseloadContext.Provider>;
};
CaseloadProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.arrayOf(PropTypes.element),
  ]),
};

export const useCaseload = () => useContext(CaseloadContext);
