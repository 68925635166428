import qs from 'query-string';
import { Axios } from 'shared/utils';
import { dayjs } from 'shared/utils';

export class AssessmentService {
  static async getDetails(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/assessment/${id}`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAssessmentsAfterMerge(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/assessment/afterMerge/${id}`,
      });

      return response.data.data.assessments;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteAssessment(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/assessment/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async reopen(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/assessment/${id}/reopen`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async completeReview(id, review = null) {
    try {
      await Axios({
        data: { review },
        method: `POST`,
        url: `/assessment/${id}/review/complete`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async returnAssessment(id, review = null) {
    try {
      await Axios({
        data: { review },
        method: `POST`,
        url: `/assessment/${id}/return`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async submitFeedback(id, comment) {
    try {
      await Axios({
        data: { comment },
        method: `POST`,
        url: `/assessment/${id}/feedback`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAssessmentListUser({ filter: _filter, limit, page }) {
    try {
      const filter = { ..._filter };
      [ `created_at`, `updated_at` ].forEach(field => {
        if (filter[field]) {
          const date = dayjs(filter[field]).set(`hour`, 0);
          filter[field] = date;
        }
      });

      const response = await Axios({
        method: `GET`,
        params: { filter, limit, page },
        url: `/assessment/user`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAssessmentUserOverview({ limit = 10, page = 1, status }) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { limit, page, status },
        url: `/assessment/user/overview`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async activateInstrument(instrument_id) {
    try {
      await Axios({
        data: {
          instrument_id,
        },
        method: `PUT`,
        url: `/instrument/activate`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deactivateInstrument(instrument_id) {
    try {
      await Axios({
        data: {
          instrument_id,
        },
        method: `PUT`,
        url: `/instrument/deactivate`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getInstrumentOutcomes(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/instrument/outcome/list`,
      });

      return response.data.data.outcomes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryMerge(mergeInfo) {
    try {
      const response = await Axios({
        data: { mergeInfo },
        method: `POST`,
        url: `/assessment/merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async retryUnMerge(unMergeInfo) {
    try {
      const response = await Axios({
        data: { unMergeInfo },
        method: `POST`,
        url: `/assessment/un-merge/retry`,
      });

      return response.data.message;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createInstrumentOutcome(outcome) {
    try {
      const response = await Axios({
        data: { outcome },
        method: `POST`,
        url: `/instrument/outcome/new`,
      });

      return response.data.data.outcome;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateInstrumentOutcome(id, outcome) {
    try {
      const response = await Axios({
        data: { outcome },
        method: `PUT`,
        url: `/instrument/outcome/${id}`,
      });

      return response.data.data.outcome;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteInstrumentOutcome(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/instrument/outcome/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreInstrumentOutcome(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/instrument/outcome/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOverrideRiskReasons(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/assessment/override-risk-reason/list`,
      });

      return response.data.data.overrideReasons;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createOverrideRiskReason(reason) {
    try {
      const response = await Axios({
        data: { reason },
        method: `POST`,
        url: `/assessment/override-risk-reason/new`,
      });

      return response.data.data.overrideReason;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateOverrideRiskReason(id, reason) {
    try {
      const response = await Axios({
        data: { reason },
        method: `PUT`,
        url: `/assessment/override-risk-reason/${id}`,
      });

      return response.data.data.overrideReason;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteOverrideRiskReason(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/assessment/override-risk-reason/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreOverrideRiskReason(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/assessment/override-risk-reason/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateAssessmentOutcome(id, outcome_id) {
    try {
      await Axios({
        method: `PUT`,
        params: { outcome_id },
        url: `/assessment/${id}/outcome`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteAssessments(ids) {
    try {
      const response = await Axios({
        method: `DELETE`,
        url: `/assessment?${qs.stringify({ ids })}`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async submitPreQuestions(id, data) {
    try {
      await Axios({
        data,
        method: `POST`,
        url: `/assessment/${id}/pre`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateAssessment(assessmentId, sectionId, response, responseId) {
    try {
      const returned = await Axios({
        data: { responseId },
        method: `POST`,
        url: `/assessment/${assessmentId}/section/${sectionId}/response/${response}/update`,
      });

      return returned.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateNotes(assessmentId, sectionId, notes) {
    try {
      await Axios({
        data: { notes },
        method: `POST`,
        url: `/assessment/${assessmentId}/section/${sectionId}/notes`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addImpression(id, impression) {
    try {
      await Axios({
        data: { impression },
        method: `PUT`,
        url: `/assessment/${id}/impression`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async submit(assessmentId, override) {
    try {
      await Axios({
        data: { override },
        method: `POST`,
        url: `/assessment/${assessmentId}/submit`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async review(assessmentId, override) {
    try {
      await Axios({
        data: { override },
        method: `POST`,
        url: `/assessment/${assessmentId}/review`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async unlock({ id }) {
    try {
      const response = await Axios({
        method: `PUT`,
        url: `/assessment/${id}/unlock`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
