import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useExpanded, useMountedLayoutEffect, useRowSelect, useSortBy, useTable } from 'react-table';
import './TheGrid.scss';

export const Grid = ({
  cellMaxWidth = 200,
  columns,
  data,
  defaultSelection = {},
  displayType = `inline-block`,
  hasMaxWidth = true,
  hoverEffect = true,
  interactableRows = false,
  onRowClick,
  onSelectedRowsChange,
  responsive = true,
  rowClick = true,
  selectionToggleHandler,
}) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    setHiddenColumns,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [ `selection` ],
        selectedRowIds: defaultSelection,
      },
    },
    useSortBy,
    useExpanded,
    useRowSelect,
  );

  // Keep parent/store state in sync with local state
  // No need to update on mount since we are passing initial state
  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [ onSelectedRowsChange, selectedRowIds ]);

  selectionToggleHandler && selectionToggleHandler({
    setSelectionEnable: (val) => {
      val ? setHiddenColumns([]) : setHiddenColumns([ `selection` ]);
    },
  });

  const rowClickHandler = (row, id) => {
    row.toggleRowExpanded();
    if (onRowClick) {
      onRowClick(id);
    }
  };

  return (
    <Table
      striped
      hover={ hoverEffect }
      responsive={responsive}
      {...getTableProps()}>
      <thead>
        {
          headerGroups.map(headerGroup =>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column =>
                  !column.disableSortBy ?
                    <th
                      className={ column.headerAlign || `text-left` }
                      {
                        ...column.getHeaderProps({
                          ...column.getSortByToggleProps(),
                          ...{ style: { width: column.width || null } },
                        })
                      }
                    >
                      <button className="btn btn-link p-0" type="button" style={{ color: `black` }}>
                        <b>{column.render(`Header`)}</b>
                        <span>
                          {
                            column.isSorted ?
                              column.isSortedDesc ?
                                <i className="float-right fa fa-caret-down" /> :
                                <i className="float-right fa fa-caret-up" /> :
                              ``
                          }
                        </span>
                      </button>
                    </th> :
                    <th
                      className={ column.headerAlign || `text-left` }
                      {
                        ...column.getHeaderProps({
                          ...column.getSortByToggleProps(),
                          ...{ style: { width: column.width || null } },
                        })
                      }
                    >
                      <b>{column.render(`Header`)}</b>
                    </th>)
              }
            </tr>)
        }
      </thead>
      <tbody {...getTableBodyProps()} data-testid="tableBody">
        {
          rows.map(row => {
            prepareRow(row);

            return <tr
              {
                ...row.getRowProps({
                  ...{
                    style: {
                      cursor: rowClick ? `pointer` : `cursor`,
                    },
                  },
                })
              }
              {...(interactableRows ? {
                "aria-label": `Expand ${row?.cells[0]?.value ? `${row?.cells[0]?.value} ` : ``}row`,
                "onKeyDown": (e) => {
                  if (document.activeElement.getAttribute(`role`) === `row`) {
                    if (e.key === ` ` || e.key === `Enter` || e.key === `Space`) {
                      rowClickHandler(row, row.original);
                      e.preventDefault();
                    }
                  }
                },
                "tabIndex": 0,
              } : {})}
              onClick={() => rowClickHandler(row, row.original)}
              className="border-bottom">
              {
                row.cells.map(cell =>
                  <td
                    className="align-middle"
                    // TODO: Maybe find a better way to fix this? StackOverflow says this is the best of the bad options
                    {...cell.getCellProps({
                      ...{
                        style: {
                          cursor: rowClick ? `pointer` : `cursor`,
                          textAlign: cell.column.textAlign || `left`,
                        },
                      },
                    })}>
                    <div
                      role="textbox"
                      onClick={e => e.stopPropagation()}
                      style={{
                        // stylelint-disable indentation
                        cursor: `auto`,
                        display: `${displayType}`,
                        maxWidth: hasMaxWidth ? `${cellMaxWidth}px` : null,
                        overflow: cell.column.id === `actions` ? `visible` : `hidden`,
                        padding: 0,
                        textOverflow: `ellipsis`,
                        whiteSpace: `nowrap`,
                      }}
                      aria-hidden="true"
                    >
                      {cell.render(`Cell`)}
                    </div>
                  </td>)
              }
            </tr>;
          })
        }
      </tbody>
    </Table>
  );
};

Grid.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSelection: PropTypes.object,
  onRowClick: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  selectionToggleHandler: PropTypes.func,
};
