import { Axios } from 'shared/utils';

export class FundingService {
  static async getFundingList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/funding/list`,
      });

      return response.data.data.fundingSources;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createFundingSource(fundingSource) {
    try {
      const response = await Axios({
        data: { fundingSource },
        method: `POST`,
        url: `/funding/new`,
      });

      return response;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateFundingSource(id, fundingSource) {
    try {
      await Axios({
        data: { fundingSource },
        method: `PUT`,
        url: `/funding/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteFundingSource(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/funding/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreFundingSource(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/funding/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
