import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useAsyncEffect } from '@react-hook/async';
import { AsyncPageWrapper } from 'shared/A-UI/AsyncPageWrapper';
import { UserService } from 'shared/services';
import { useHistory } from 'react-router-dom';

interface LoginDTO {
  username: string;
  password: string;
}

interface User {
  badges: UserBadge[];
  certificates: UserCertificate[];
  info: UserInfo;
}

interface UserBadge {
  awarded_on: string;
  badge: Badge;
  badge_id: number;
  id: number;
  user_id: number;
}

interface Badge {
  id: number;
  name: string;
  application_id: number;
  interval: string;
  image_id: number;
  badge_category_id: number;
}

interface UserCertificate {
  id: number;
  user_id: number;
  certificate_id: number;
  expiration_date: string | null;
  participant_id: number;
  certificate: Certificate;
}

interface Certificate {
  id: number;
  name: string;
  application_id: number;
  abbreviation: string;
  expiration_length: string | null;
  const: string;
}

interface UserInfo {
  first_name: string;
  last_name: string;
  email: string;
  id: number;
  username: string;
  role: Role;
  roles: Role[];
  avatar: Avatar | null;
  agency: Agency;
  absoluteAgency: Agency;
  disabled_at: null;
  deleted_at: null;
  phone: string | null;
  title: string | null;
  tutorial_check: 0 | 1;
  audit: Audit;
}

interface Agency {
  id: number;
  name: string;
  parent_id: number | null;
  import_id: number | null;
  avatar_id: number | null;
  deleted_at: null;
  account_number: string | null;
  settings: Settings;
  terms: AgencyTerms;
}

interface Settings {
  MAP_LONGITUDE: string;
  MAP_LATITUDE: string;
  MAP_ZOOM: string;
  DEFAULT_OFFENDER_TYPE: string;
  BACKDATE_LIMIT: string;
}

interface AgencyTerms {
  caseplan: string | null;
  complete_caseplan: string | null;
  offender: string | null;
  goal_level_0: string | null;
  goal_level_1: string | null;
  goal_level_2: string | null;
}

interface Terms {
  caseplan_article: Article;
  caseplan_term: string | `Case Plan`;
  complete_caseplan_term: string | `Complete`;
  goal_article: Article;
  goal_term: string | `Goal`;
  objective_article: Article;
  objective_term: string | `Objective`;
  offender_article: Article;
  offender_term: string | `Offender`;
  technique_article: Article;
  technique_term: string | `Technique`;
}

type Article = `a` | `an`;

interface Audit {
  id: number;
  user_id: number;
  last_login: string;
  release_notice_check: boolean;
}

interface Avatar {
  id: number;
  name: string;
  local_name: string;
  size: string;
  description: string;
  encoding: string;
  extension: string;
  is_avatar: boolean;
  created_by: number;
  mime_type: string;
  created_at: string;
  url: string;
  avatar_url: string;
  search_avatar_url: string;
}

interface Role {
  id: number;
  name: string;
  rank: number;
  agency_id: number;
  import_id: number | null;
  application_id: number;
  is_template: boolean;
  deleted_at: null;
  permissions: Permission[];
  scaryPermissions: ScaryPermission[];
  _pivot_id: number;
  _pivot_user_id: number;
  _pivot_role_id: number;
}

interface Permission {
  id: number;
  name: string;
  application_id: number;
  action_id: number;
  action: string;
  subject_id: number;
  subject: string;
  _pivot_id: number;
  _pivot_role_id: number;
  _pivot_permission_id: number;
}

interface ScaryPermission {
  id: number;
  name: string;
  application_id: number;
  action_id: number;
  action: string;
  subject_id: number;
  subject: string;
  _pivot_id: number;
  _pivot_role_id: number;
  _pivot_permission_id: number;
}

export interface IAuthContext {
  user: User | null;
  terms: Terms;
  permissions: Permission[];
  scaryPermissions: ScaryPermission[];
  login: (data: LoginDTO, referrer?: string) => Promise<void>;
  logout: () => Promise<void>;
}

const checkIsAuthenticated = () => {
  const cookies = new Cookies();
  const spa_token: string = cookies.get(`spa_token`);

  if (spa_token) {
    return !!JSON.parse(spa_token);
  }

  return false;
};

const bootstrapAppData = async (): Promise<User | null> => {
  if (checkIsAuthenticated()) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return UserService.getProfile();
  }

  return null;
};

export const AuthContext = React.createContext<IAuthContext | undefined>(undefined);
AuthContext.displayName = `AuthContext`;

export const AuthProvider: React.FC = (props: any) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState(() => checkIsAuthenticated());
  const { error, status, value: user } = useAsyncEffect(() => bootstrapAppData(), [ isAuthenticated ]);
  const [ terms, setTerms ] = useState<Terms>();
  const [ permissions, setPermissions ] = useState<Permission[]>();
  const [ scaryPermissions, setScaryPermissions ] = useState<ScaryPermission[]>();
  const [ info, setInfo ] = useState<UserInfo>();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      const offender_term = user.info.agency.terms?.offender || `Offender`;
      const caseplan_term = user.info.agency.terms?.caseplan || `Case Plan`;
      const complete_caseplan_term = user.info.agency.terms?.complete_caseplan || `Complete`;
      const goal_term = user.info.agency.terms?.goal_level_0 || `Goal`;
      const objective_term = user.info.agency.terms?.goal_level_1 || `Objective`;
      const technique_term = user.info.agency.terms?.goal_level_2 || `Technique`;

      const articleTransform = (term: string) => [ `a`, `e`, `i`, `o`, `u` ]
        .includes(term.toLowerCase().charAt(0)) ? `an` : `a`;

      setTerms({
        caseplan_article: articleTransform(caseplan_term),
        caseplan_term,
        complete_caseplan_term,
        goal_article: articleTransform(goal_term),
        goal_term,
        objective_article: articleTransform(objective_term),
        objective_term,
        offender_article: articleTransform(offender_term),
        offender_term,
        technique_article: articleTransform(technique_term),
        technique_term,
      });

      setPermissions(user.info.role.permissions);
      setScaryPermissions(user.info.role.scaryPermissions);

      setInfo(user.info);
    }
  }, [ user ]);

  const login = React.useCallback((form: LoginDTO, referrer?: string) =>
    UserService
      .login(form)
      .then(() => {
        setIsAuthenticated(true);

        if (referrer) {
          history.push(referrer);
        }
      }),
  [ history ]);

  const logout = React.useCallback(() => {
    void UserService.logout().then(() => setIsAuthenticated(false));
  }, [ ]);

  const value = React.useMemo(() =>
    // eslint-disable-next-line sort-keys
    ({ scaryPermissions, login, logout, user, info, setInfo, terms, permissions }),
  [ scaryPermissions, login, logout, user, info, setInfo, terms, permissions ]);

  return (
    <AsyncPageWrapper status={status} error={error} height="100vh">
      <AuthContext.Provider value={value} {...props} />
    </AsyncPageWrapper>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
