import { Axios } from 'shared/utils';

export class OffenderService {
  static async getOffenders(page, limit, filter) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { filter, limit, page },
        url: `/offender/list`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static bulkLoadOffenders = async (data) => {
    try {
      const response = await Axios({
        data,
        method: `POST`,
        url: `/offender/new/bulk`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  };

  static async getStateList(all = false) {
    try {
      const response = await Axios({
        cache: true,
        method: `GET`,
        params: { all },
        url: `/address/state/list`,
      });

      return response.data.data.states;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateAddress(id, address, offender_id) {
    try {
      const response = await Axios({
        data: {
          address,
          offender_id,
        },
        method: `PUT`,
        url: `/offender/${offender_id}/address/${id}`,
      });

      return response.data.data.address;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteAddress(id, offender_address_id, offender_id) {
    try {
      await Axios({
        method: `DELETE`,
        params: { offender_address_id, offender_id },
        url: `/address/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreAddress(id, offender_address_id) {
    try {
      await Axios({
        method: `PUT`,
        params: { offender_address_id },
        url: `/address/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffenderStatusList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/offender/status/list`,
      });

      return response.data.data.statuses;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createOffenderStatus(status) {
    try {
      const response = await Axios({
        data: { status },
        method: `POST`,
        url: `/offender/status/new`,
      });

      return response;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async mergeOffenders(offenders, newCreatingAgency) {
    try {
      const response = await Axios({
        data: { newCreatingAgency, offenders },
        method: `POST`,
        url: `/offender/merge`,
      });

      return response.data.data.offender;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateMergeInfo(info) {
    try {
      const response = await Axios({
        data: { info },
        method: `POST`,
        url: `/offender/merge/update`,
      });

      return response.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateUnMergeInfo(mergeData) {
    try {
      const response = await Axios({
        data: { mergeData },
        method: `POST`,
        url: `/offender/un-merge/update`,
      });

      return response.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async unMergeOffenders(mergeInfo) {
    try {
      const response = await Axios({
        data: { mergeInfo },
        method: `POST`,
        url: `/offender/un-merge`,
      });

      return response.data.data.unMergeStatus;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateOffenderStatus(id, status) {
    try {
      await Axios({
        data: { status },
        method: `PUT`,
        url: `/offender/status/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteOffenderStatus(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offender/status/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static changeAvatar(id, data) {

    return Axios({
      data,
      method: `POST`,
      url: `/offender/${id}/avatar`,
    });
  }

  static async restoreOffenderStatus(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/offender/status/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getWorkerReasons(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/offender/reason-for-change/list`,
      });

      return response.data.data.reasons;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async checkMergedOffender(offenderId) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { offenderId },
        url: `/offender/merge/check`,
      });

      return response.data.data.offenders;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getMergeInfo(offenderId) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { offenderId },
        url: `/offender/merge/info`,
      });

      return response.data.data.info;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getMergeInfoForUser(userId) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { userId },
        url: `/offender/merge/${userId}/info`,
      });

      return response.data.data.info;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createWorkerReason(reason) {
    try {
      const response = await Axios({
        data: { reason },
        method: `POST`,
        url: `/offender/reason-for-change/new`,
      });

      return response.data.data.reason;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateWorkerReason(id, reason) {
    try {
      const response = await Axios({
        data: { reason },
        method: `PUT`,
        url: `/offender/reason-for-change/${id}`,
      });

      return response.data.data.reason;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteWorkerReason(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offender/reason-for-change/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreWorkerReason(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/offender/reason-for-change/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getStatusesByOffender(id, onlyWithinUserAgency) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { onlyWithinUserAgency },
        url: `/offender/${id}/statuses`,
      });

      return response.data.data.offenderStatuses;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getCaseload() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/caseload`,
      });

      return response.data.data.caseload;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getUserCaseLoad(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/${id}/caseload`,
      });
      if (response.status !== 200) {
        throw new Error(`Failed to get caseload`);
      }

      return response.data.data.caseload;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getInfo(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${id}`,
      });

      return response.data.data.offender;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getProgramInfo(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${id}/program`,
      });

      return response.data.data.programs;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAssessmentInfo(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${id}/assessment`,
      });

      return response.data.data.assessments;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async search(q, onlyWithinUserAgency = false, includeShares = true) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { includeShares, onlyWithinUserAgency, q },
        url: `/offender/search`,
      });

      return response.data.data.offenders;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async searchMergedOffenders(q, onlyWithinUserAgency = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { onlyWithinUserAgency, q },
        url: `/offender/merge/search`,
      });

      return response.data.data.offenders;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addNewAddress(offender_id, address) {
    try {
      const response = await Axios({
        data: { address },
        method: `POST`,
        url: `/offender/${offender_id}/address`,
      });

      return response.data.data.address;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async editInfo(id, offender) {
    try {
      const response = await Axios({
        data: { offender },
        method: `PUT`,
        url: `/offender/${id}`,
      });

      return response.data.data.offender;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getGenderList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/gender/list`,
      });

      return response.data.data.genders;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getEthnicityList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/ethnicity/list`,
      });

      return response.data.data.ethnicities;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getRaceList(all = false) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/offender/race/list`,
      });

      return response.data.data.races;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAddressInfo(offender_id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${offender_id}/address`,
      });

      return response.data.data.addresses;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateStatus(id, offender_status_id) {
    try {
      const response = await Axios({
        data: { offender_status_id },
        method: `PUT`,
        url: `/offender/${id}/status`,
      });

      return response.data.data.status;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async removeExternalId(offender_id, externalId) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offender/${offender_id}/external-id/${externalId}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getExternalIdTypeList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/external-id/type/list`,
      });

      return response.data.data.externalIdTypes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addExternalId(offender_id, externalId) {
    try {
      const response = await Axios({
        data: { externalId },
        method: `POST`,
        url: `/offender/${offender_id}/external-id`,
      });

      return response.data.data.externalId;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async saveWorker(offender, user_id, worker_type_id) {
    try {
      const response = await Axios({
        data: { user_id, worker_type_id },
        method: `POST`,
        url: `/offender/${offender}/worker`,
      });

      return response.data.data.workers;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async removeWorker(id, offender_id) {
    try {
      await Axios({
        method: `DELETE`,
        params: { offender_id },
        url: `/offender/worker/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteOffender(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offender/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffenseInfo(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${id}/offense`,
      });

      return response.data.data.offenses;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getCaseplanInfo(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${id}/caseplan`,
      });

      return response.data.data.caseplans;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDataShareCategories() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/share/category/list`,
      });

      return response.data.data.categories;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getSharesList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/data/share/list`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async transfer(id, offenderTransfer) {
    try {
      return await Axios({
        data: { offenderTransfer },
        method: `PUT`,
        url: `/offender/${id}/transfer`,
      });
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async share(id, offenderShare) {
    try {
      return await Axios({
        data: { offenderShare },
        method: `POST`,
        url: `/offender/${id}/share`,
      });
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async revokeShare(id, agencyId) {
    try {
      await Axios({
        method: `DELETE`,
        params: { agencyId },
        url: `/offender/data/share/${id}/agencyId/${agencyId}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOffenderInstrument(offenderId) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${offenderId}/instrument`,
      });

      return response.data.data.instruments;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  /**
   * @param {number} id
   * @returns {Promise<{
   *  shares: object;
   *  agencies: object[];
   *  offenderName: string;
   * }>}
   */
  static async getSharesForOffender(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/data/share/offender/${id}`,
      });

      return response.data.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getSuffixList() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/suffix/list`,
      });

      return response.data.data.suffixes;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createAssessment(offenderId, instrumentId, createDate) {
    try {
      const response = await Axios({
        data: { createDate, instrumentId },
        method: `POST`,
        url: `/offender/${offenderId}/assessment`,
      });

      return response.data.data.assessmentId;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getAgencyActiveStatusID() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/active/status`,
      });

      return response.data.data.statuses.agencyActiveStatusID;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createOffender(offender, override = false) {
    try {
      const response = await Axios({
        data: { offender, override },
        method: `POST`,
        url: `/offender/new`,
      });

      return response.data.data.offender;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async checkForPossibleDuplicates(first_name, last_name, date_of_birth, externalId) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { date_of_birth, externalId, first_name, last_name },
        url: `/offender/duplicate-check`,
      });

      return response.data.data.offenders;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createCaseNote({ offenderId, subject, text }) {
    try {
      const response = await Axios({
        data: { subject, text },
        method: `POST`,
        url: `/offender/${offenderId}/case-note`,
      });

      return response.data.data.caseNote;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getCaseNotesForOffender({ offenderId }) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/offender/${offenderId}/case-notes`,
      });

      return response.data.data.caseNotes;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteCaseNote({ caseNoteId, offenderId }) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/offender/${offenderId}/case-note/${caseNoteId}`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateCaseNote({ caseNoteId, offenderId, subject, text }) {
    try {
      await Axios({
        data: { subject, text },
        method: `PUT`,
        url: `/offender/${offenderId}/case-note/${caseNoteId}`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
