import { Axios } from 'shared/utils';

export class HelpService {
  static getFAQs() {
    try {
      const response = Axios({
        cache: true,
        method: `GET`,
        url: `/help/faq/list`,
      });

      return response.data.data.faqs;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getChangelog() {
    try {
      const response = await Axios({
        cache: true,
        method: `GET`,
        url: `/help/changelog/list`,
      });

      return response.data.data.changelog;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getVideos() {
    try {
      const response = await Axios({
        cache: true,
        method: `GET`,
        url: `/help/video/list`,
      });

      return response.data.data.videos;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static getIntroOptions() {
    // Intro.js options
    const introOptions = {
      disableInteraction: true,
      doneLabel: `Finish`,
      exitOnEsc: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      nextLabel: `Next`,
      positionPrecedence: [ `left`, `right`, `bottom`, `top` ],
      prevLabel: `Back`,
      scrollToElement: false,
      showBullets: false,
      showProgress: true,
      showStepNumbers: false,
      steps: [{
        // eslint-disable-next-line max-len
        intro: `This is your main view. The main view shows all the information related to the page you are currently viewing. You can change what's shown here by changing tabs on the left menu.`,
      }, {
        element: `#leftmenu`,
        // eslint-disable-next-line max-len
        intro: `This is the left menu. The left menu contains all the pages you have access to, allows you to reach the help page, and logout.`,
      }, {
        element: `#logo`,
        intro: `Here you can see your profile picture, view the help page, and logout.`,
      }, {
        element: `#scroll`,
        // eslint-disable-next-line max-len
        intro: `These are all the different pages you have access to. Clicking on a menu item will open a dropdown that will allow you to access the subpages.`,
      }, {
        element: `#rightmenu`,
        // eslint-disable-next-line max-len
        intro: `This is the right menu. Here you can see different things at a glance, such as your unread notifications and your case load. It also allows you to search through you case load.`,
      }, {
        element: `#notifications`,
        // eslint-disable-next-line max-len
        intro: `Clicking here will take you to your unread notifications. When you have an unread notification, you will see a red bubble on the top right of the bell, accompanied by a number representing the number of unread notifications you have.`,
      }, {
        element: `#searchWrap`,
        // eslint-disable-next-line max-len
        intro: `This search box allows you to search through your current case load. You can use the First Name and Last Name of a youth in your search.`,
      }, {
        element: `#caseload`,
        // eslint-disable-next-line max-len
        intro: `Here you can view your current case load at a glance. Clicking on a youth will bring you to the specified detail page. You can scroll through your case load to show hidden youths.`,
      }, {
        // eslint-disable-next-line max-len
        intro: `You can view the tutorial again by navigating to the help page by clicking 'HELP' in the top left, and clicking the 'Restart Tutorial' button.`,
      }],
      tooltipPosition: `auto`,
    };

    return introOptions;
  }
}
