import arrify from 'arrify';
import { Axios } from 'shared/utils';
import qs from 'query-string';

export class RoleService {
  static async getAllRolesForAgency(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/role/agency/${id}`,
      });

      return response.data.data.roles;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getRoleList(page, limit, full) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { full, limit, page },
        url: `/role/list`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async search(q) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { q },
        url: `/role/search`,
      });

      return response.data.data.roles;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async searchFilter(filter, page, limit) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { filter, limit, page },
        url: `/role/search/filter`,
      });

      return response.data.data.roles.roles;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getDetails(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/role/${id}/info`,
      });

      return response.data.data.role;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async create(role, permissions, groups) {
    try {
      const response = await Axios({
        data: { groups, permissions, role },
        method: `POST`,
        url: `/role/new`,
      });

      return response.data.data.role;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async update(id, role) {
    try {
      const response = await Axios({
        data: { role },
        method: `PUT`,
        url: `/role/${id}`,
      });

      return response.data.data.role;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getGroupedPermissions() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/permission/group`,
      });

      return response.data.data.groups;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getRelatedPermissions(ids) {
    try {
      const arrified = arrify(ids);
      const query = qs.stringify({
        ids: arrified,
      });

      const response = await Axios({
        method: `GET`,
        url: `/permission/related?${query}`,
      });

      return response.data.data.permissions;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getRelatedPermissionInfo() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/permission/related/info`,
      });

      return response.data.data.permissionInfo;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getTemplates(all) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { all },
        url: `/role/templates`,
      });

      return response.data.data.roles;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteTemplate(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/role/template/${id}`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreTemplate(id) {
    try {
      await Axios({
        method: `PUT`,
        url: `/role/template/${id}`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
