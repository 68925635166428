import { Axios } from 'shared/utils';

export class AnnouncementService {
  static async getUserAnnouncements() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/announcement`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async delete(id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/notification/announcement/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async create(announcement) {
    try {
      const response = await Axios({
        data: { announcement },
        method: `POST`,
        url: `/notification/announcement/new`,
      });

      return response.data.data.announcement;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
