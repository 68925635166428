import { Axios } from 'shared/utils';

export class AddressService {
  static async getStateList(all = false) {
    try {
      const response = await Axios({
        cache: true,
        method: `GET`,
        params: { all },
        url: `/address/state/list`,
      });

      return response.data.data.states;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateAddress(id, address, offender_id) {
    try {
      const response = await Axios({
        data: {
          address,
          offender_id,
        },
        method: `PUT`,
        url: `/address/${id}`,
      });

      return response.data.data.address;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteAddress(id, offender_address_id, offender_id) {
    try {
      await Axios({
        method: `DELETE`,
        params: { offender_address_id, offender_id },
        url: `/address/${id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async restoreAddress(id, offender_address_id) {
    try {
      await Axios({
        method: `PUT`,
        params: { offender_address_id },
        url: `/address/${id}/restore`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
