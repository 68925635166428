import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { create } from 'socketcluster-client';
import { NotificationService } from 'shared/services';
import { handleError, triggerNotification } from 'shared/A-UI';

const connectToSocket = async (onMessage) => {
  const { application_channel, token, user_channel } = await NotificationService.getLiveToken();
  const { REACT_APP_NOTIFICATION_HOSTNAME, REACT_APP_NOTIFICATION_PORT } = process.env;

  const socket = create({
    hostname: REACT_APP_NOTIFICATION_HOSTNAME,
    port: parseInt(REACT_APP_NOTIFICATION_PORT, 10),
    query: { token },
    secure: true,
  });

  (async () => {
    const notificationApplicationChannel = socket.subscribe(application_channel);
    for await (const data of notificationApplicationChannel) {
      onMessage(data);
    }

    const notificationUserChannel = socket.subscribe(user_channel);
    for await (const data of notificationUserChannel) {
      onMessage(data);
    }

    const errors = socket.listener(`error`);
    for await (const { error } of errors) {
      handleError(error);
    }
  })();

  return () => {
    socket.unsubscribe(application_channel);
    socket.unsubscribe(user_channel);
    socket.closeListener(`error`);
  };
};

const context = createContext({ fetchNotifications: () => {}, notifications: [], unreadNotifications: 0 });

export const NotificationProvider = ({ children }) => {
  const [ notifications, setNotifications ] = useState([]);
  const [ unreadNotifications, setUnreadNotifications ] = useState(0);

  const receivedNotification = ({ body, subject }) => {
    triggerNotification({
      message: body,
      title: subject,
      variant: `default`,
    });
    setUnreadNotifications(unread => unread + 1);
  };

  useEffect(() => {
    // Get unread count
    const getUnreadCount = async () => {
      const unread = parseInt(await NotificationService.getUnreadCount(), 10);
      setUnreadNotifications(unread);
    };
    getUnreadCount();

    // Subscribe to server
    const unsubscribe = connectToSocket(receivedNotification);

    return unsubscribe;
  }, []);

  const fetchNotifications = useCallback(async () => {
    const latest = await NotificationService.getLatest();
    setNotifications(latest);

    const newlyRead = latest.map(({ id }) => id);
    await NotificationService.markAsRead(newlyRead);

    setUnreadNotifications(unread => {
      const newCount = unread - newlyRead.length;

      return newCount < 0 ? 0 : unread - newlyRead.length;
    });
  }, []);

  const contextValue = { fetchNotifications, notifications, unreadNotifications };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};
NotificationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.arrayOf(PropTypes.element),
  ]),
};

export const useNotifications = () => useContext(context);
