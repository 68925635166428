import React from 'react';
import PropTypes from 'prop-types';

import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import styled from '@emotion/styled';
import { sanitize } from 'dompurify';
import { colors } from './colors';

const PopoverHeading = styled.h3({
  /* stylelint-disable indentation */
  backgroundColor: colors.grey_blue,
  borderBottom: `1px solid #ebebeb`,
  borderRadius: `2px 2px 0 0`,
  color: colors.white,
  fontSize: `1rem`, // TODO: @font-size-base
  margin: 0,
  padding: `8px 14px`,
});

const createMarkupHelpText = ({ body: html }) => ({
  __html: sanitize(html, {
    FORBID_ATTR: [ `style` ],
    FORBID_TAGS: [ `img`, `style` ],
    USE_PROFILES: { html: true },
  }),
});

export const ModulePopover = ({ body, children, placement, rootClose = true, title, trigger = `click` }) =>
  <OverlayTrigger
    trigger={trigger}
    placement={placement}
    rootClose={rootClose}
    overlay={
      <Popover id="popover-basic" style={{ width: `34em` }}>
        <label
          htmlFor="overlay_help_text"
          id="lbl_overlay_help_text"
          className="sr-only">
          {body}
        </label>
        <div id="overlay_help_text"
          name="overlay_help_text"
          data-testid="overlay_help_text"
          aria-labelledby="lbl_overlay_help_text"
          aria-live="assertive"
          role="status">
          <PopoverHeading as="h3">
            {title}
          </PopoverHeading>
          <Popover.Content
            style={{
              fontFamily: `Helvetica Neue, Helvetica, Arial, sans-serif`,
              fontSize: `1rem`,
              padding: `9px 14px`,
            }}>
            <p
              aria-live="assertive"
              role="status"
              className="mb-0"
              dangerouslySetInnerHTML={createMarkupHelpText({ body })} />
          </Popover.Content>
        </div>
      </Popover>
    }
  >
    {
      children ?
        children :
        <Button variant="custom" type="button" data-testid="helpButton" >?</Button>
    }
  </OverlayTrigger>;
ModulePopover.propTypes = {
  body: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.arrayOf(PropTypes.element),
  ]),
  placement: PropTypes.string,
  title: PropTypes.string,
};
