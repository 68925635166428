import { Axios } from 'shared/utils';

export class GrantTrackingService {

  static async getFormData() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/grant-tracking/form/data`,
      });

      return response.data.data;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getForm(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/grant-tracking/form/${id}`,
      });

      return response.data.data.form;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getForms(query) {
    try {
      const response = await Axios({
        method: `GET`,
        params: query,
        url: `/grant-tracking/form`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getSupportActivities() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/grant-tracking/support-activity`,
      });

      return response.data.data.supportActivities;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getSupportActivityForm(id) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/grant-tracking/support-activity/form/${id}`,
      });

      return response.data.data.form;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getSupportActivityForms(query) {
    try {
      const response = await Axios({
        method: `GET`,
        params: query,
        url: `/grant-tracking/support-activity/form`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async createSupportActivityForm(form) {
    try {
      const response = await Axios({
        data: { form },
        method: `POST`,
        url: `/grant-tracking/support-activity/form`,
      });

      return response.data.data.form;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async addSupportActivity(id, activity) {
    try {
      const response = await Axios({
        data: { activity },
        method: `POST`,
        url: `/grant-tracking/support-activity/form/${id}/activity`,
      });

      return response.data.data.activity;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async updateSupportActivityForForm(form_id, activity_id, activity) {
    try {
      const response = await Axios({
        data: { activity },
        method: `PUT`,
        url: `/grant-tracking/support-activity/form/${form_id}/activity/${activity_id}`,
      });

      return response.data.data.activity;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async deleteSupportActivityForForm(form_id, activity_id) {
    try {
      await Axios({
        method: `DELETE`,
        url: `/grant-tracking/support-activity/form/${form_id}/activity/${activity_id}`,
      });

    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
