import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CloseButton, Form, InputGroup, Modal } from 'react-bootstrap';
import owasp from 'owasp-password-strength-test';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { UserService } from 'shared/services';
import { handleError, handleSuccess } from 'shared/A-UI';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

export const ChangePasswordModal = ({ id, isScary, onReject, showModal }) => {
  const [ owaspErrors, setOwaspErrors ] = useState(owasp.test(` `).requiredTestErrors);
  const [ password, setPassword ] = useState();
  const [ confirmPassword, setConfirmPassword ] = useState();
  const [ passwordShown, setPasswordShown ] = useState();

  owasp.config({
    allowPassphrases: true,
    maxLength: 64,
    minLength: 10,
    minOptionalTestsToPass: 1,
    minPhraseLength: 20,
  });

  const { errors, handleSubmit, register, reset, watch } = useForm({
    defaultValues: {
      confirmPassword: ``,
      password: ``,
    },
  });

  const submit = () => {
    if (isScary) {
      UserService
        .scaryResetPassword({ confirmPassword, id, password })
        .then(() => {
          handleSuccess(`This user's password has successfully been updated`);
          onReject();
          setPassword(``);
          setConfirmPassword(``);
          reset();
        })
        .catch(() => {
          handleError(new Error(`An error occurred while resetting this user's password. Please try again`));
        });
    } else {
      UserService
        .authenticatedPasswordReset({ confirmPassword, password })
        .then(() => {
          handleSuccess(`Your password has successfully been updated`);
          onReject();
          setPassword(``);
          setConfirmPassword(``);
          reset();
        })
        .catch(() => {
          handleError(new Error(`An error occurred while resetting this user's password. Please try again`));
        });
    }
  };

  const togglePasswordShown = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Modal
      aria-labelledby="msg"
      animation
      scrollable
      data-testid="adminChangePasswordModal"
      show={showModal}
      size="md"
      centered
      onHide={
        () => {
          if (isScary) {
            onReject();
          }
        }
      }>
      <Modal.Header style={{ display: `block` }}>
        <div className="row">
          <div className="col-md-12">
            <div className="module-header-custom">
              <Modal.Title id="msg" name="msg">Change Password</Modal.Title>
              {
                isScary &&
                  <CloseButton data-testid="closeBtn" onClick={onReject} />
              }
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form id="resetPasswordForm" onSubmit={handleSubmit(submit)} className="clearfix">
          <Form.Group>
            {
              owaspErrors.map(error => <Form.Text key={`error:${error}`}>{error}</Form.Text>)
            }
          </Form.Group>
          <Form.Group>
            <InputGroup>
              <Form.Control
                id="passwordField"
                aria-label="Password"
                className={`form-control ${cn({ 'is-invalid': !!errors?.password })}`}
                placeholder="Password"
                type={passwordShown ? `text` : `password`}
                style={{
                  border: `1px solid #ced4da`,
                  borderColor: errors?.password ? `#dc3545` : `#ced4da`,
                  borderRadius: `4px`,
                  boxShadow: errors?.password ? `0 0 5px #dc3545` : ``,
                }}
                {...register(`password`, {
                  maxLength: {
                    message: `Password cannot be more than 64 characters long`,
                    value: 64,
                  },
                  minLength: {
                    message: `Password must be at least 10 characters long`,
                    value: 10,
                  },
                  required: `You must enter a password`,
                  validate: {
                    strength: (value) => owasp.test(value).strong,
                  },
                })}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setOwaspErrors(owasp.test(e.target.value).requiredTestErrors);
                }}
                value={password}
                name="password"
                required
              />
              <InputGroup.Append
                aria-label="Show/Hide Password"
                style={{ borderRadius: `4px` }}>
                <Button
                  variant="secondary-outline"
                  size="sm"
                  className="px-3 border-left-0"
                  style={{ borderColor: `#ced4da` }}
                  onClick={() => { togglePasswordShown(); }}>
                  {
                    passwordShown ?
                      <IoMdEye aria-label="Password Shown" /> :
                      <IoMdEyeOff aria-label="Password Hidden" />
                  }
                </Button>
              </InputGroup.Append>
            </InputGroup>
            {
              errors?.password &&
                <Form.Text style={{ color: `red` }}>{errors?.password?.message}</Form.Text>
            }
          </Form.Group>
          <Form.Group>
            <Form.Control
              id="confirmPasswordField"
              aria-label="Confirm password"
              type={passwordShown ? `text` : `password`}
              className={`form-control ${cn({ 'is-invalid': !!errors?.password })} mb-3`}
              placeholder="Confirm Password"
              style={{
                border: `1px solid #ced4da`,
                borderColor: errors?.password ? `#dc3545` : `#ced4da`,
                borderRadius: `4px`,
                boxShadow: errors?.password ? `0 0 5px #dc3545` : ``,
              }}
              {...register(`confirmPassword`, {
                required: `You must confirm your password`,
                validate: {
                  matches: (value) => value === watch(`password`),
                },
              })}
              autoComplete="new-password"
              name="confirmPassword"
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            {
              errors?.confirmPassword &&
                <Form.Text style={{ color: `red` }}>{errors.confirmPassword.message}</Form.Text>
            }
            {
              errors?.confirmPassword?.type === `matches` &&
                <Form.Text style={{ color: `red` }}>Passwords do not match</Form.Text>
            }
          </Form.Group>
          <Button
            variant="danger"
            id="loginBtn"
            className="btn btn-custom float-right"
            type="submit">
            Confirm</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
ChangePasswordModal.propTypes = {
  id: PropTypes.number,
  isScary: PropTypes.bool,
  onReject: PropTypes.func,
  showModal: PropTypes.bool,
};
