import { Axios } from 'shared/utils';

export class ReportsService {
  static getReportsList(moduleId) {
    return Axios({
      method: `GET`,
      url: `/report/${moduleId}/list`,
    }).then(response => {
      if (response.status !== 200) {
        throw new Error(`Failed to get reports list`);
      }

      return response.data.data.reports.reports.reports;
    });
  }

  static getModules() {
    return Axios({
      method: `GET`,
      url: `/report/module`,
    }).then(response => {
      if (response.status !== 200) {
        throw new Error(`Failed to get reports module list`);
      }

      return response.data.data.modules.modules.modules;
    });
  }

  static viewReport(reportId) {
    return Axios({
      method: `GET`,
      url: `/report/${reportId}/view`,
    }).then(response => {
      if (response.status !== 200) {
        throw new Error(`Failed to view report`);
      }

      return response.data;
    });
  }
}
