import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Dropdown } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';

export const FilterDropdown = ({ className, debounce = 300, onChange, onClose, onOpen, schema }) =>
  <Dropdown
    className={className}
    onToggle={(show) => {
      if (onOpen) {
        if (show) {
          onOpen();
        }
        else {
          onClose();
        }
      }
    }} >
    <Dropdown.Toggle
      variant="custom"
      data-testid="Filter"
      id="filter-dropdown-button">
      Filter
    </Dropdown.Toggle>
    <Dropdown.Menu className="filter">
      <form aria-labelledby="filter-dropdown-button" data-testid="filterForm">
        {
          schema.map(node =>
            <React.Fragment key={node.label}>
              <label
                htmlFor={`${node.name}`}
                id={`lbl-${node.name}`}
                data-testid={`${node.name}`}
                className="form-group w-100">
                { node.label }
                {
                  node.type === `divider` ?
                    <br /> :
                    node.type === `select` ?
                      node.isAsync ?
                        <AsyncSelect
                          backspaceRemovesValue
                          classNamePrefix="react-select"
                          id={`${node.name}`}
                          aria-labelledby={`lbl-${node.name}`}
                          {...node}
                        /> :
                        <Select
                          classNamePrefix="react-select"
                          id={`${node.name}`}
                          aria-labelledby={`lbl-${node.name}`}
                          {...node}
                        /> :
                    // eslint-disable-next-line react/jsx-indent
                      <div className="d-flex justify-content-start">
                        <DebounceInput
                          debounceTimeout={debounce}
                          className="form-control"
                          id={`${node.name}`}
                          data-testid={`${node.name}`}
                          onChange={onChange}
                          {...node}
                          aria-describedby={node.type === `date` ? `dateFormat` : undefined}
                        />
                        {
                          !!(node.type === `date`) &&
                            <span id="dateFormat" className="sr-only">mm/dd/yyyy</span>
                        }
                      </div>
                }
              </label>
            </React.Fragment>)
        }
      </form>
    </Dropdown.Menu>
  </Dropdown>;
FilterDropdown.propTypes = {
  className: PropTypes.string,
  debounce: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.array.isRequired,
};
