import React from 'react';
import { useAuth } from 'shared/providers';
import { Lottie } from 'shared/A-UI';
import 'scss/node-styles.scss';
import 'scss/custom.scss';

const AuthenticatedApp = React.lazy(() => import(`./entry/AuthenticatedApp`));
const UnauthenticatedApp = React.lazy(() => import(`./entry/UnauthenticatedApp`));

const App = () => {
  const { user } = useAuth();

  return (
    <React.Suspense fallback={<Lottie lottie="ra" width={100} height={100} />}>
      {
        user ?
          <AuthenticatedApp /> :
          <UnauthenticatedApp />
      }
    </React.Suspense>
  );
};

export default App;
