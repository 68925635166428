import { Axios } from 'shared/utils';
const qs = require(`query-string`);

export class UserService {
  static async reset({ confirmPassword, otk, password }) {
    try {
      const response = await Axios({
        data: { confirmPassword, otk, password },
        method: `POST`,
        url: `/reset/password`,
      });

      return response.data.resetToken;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async checkResetToken(otk) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { otk },
        url: `/reset/password`,
      });

      return response;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async requestPasswordResetEmail({ username }) {
    try {
      await Axios({
        data: { username },
        method: `POST`,
        url: `/request/password`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async requestUsernameEmail({ email }) {
    try {
      await Axios({
        data: { email },
        method: `POST`,
        url: `/request/username`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async login({ password, username }) {
    try {
      const response = await Axios({
        data: { password, username },
        method: `POST`,
        url: `/login`,
      });

      return response.data.data.user;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async logout() {
    try {
      await Axios({
        method: `POST`,
        url: `/logout`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getInfo() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/info`,
      });

      return response.data.data.user;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async setReleaseNoticeCheck() {
    try {
      const response = await Axios({
        method: `PUT`,
        url: `/user/release`,
      });

      return response.data.data.user;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }

  }

  static async getProfile() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/profile`,
      });

      return response.data.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getOrganization({ all = false } = {}) {
    try {
      const response = await Axios({
        method: `GET`,
        params: {
          all,
        },
        url: `/user/agency/organization`,
      });

      return response.data.data.agencies;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getPermissions(dontUnwrap = false) {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/user/permissions?dontUnwrap=${dontUnwrap}`,
      });

      return response.data.data.permissions;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async sendMassEmail({ body, roles, subject }) {
    try {
      await Axios({
        data: { body, roles, subject },
        method: `POST`,
        url: `/user/email/mass`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async search(query, limit = 30) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { limit },
        url: `/user/search?q=${query}`,
      });

      return response.data.data.users;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static getUsersList = async (page, limit, filter) => {
    const response = await Axios({
      method: `GET`,
      params: { filter, limit, page },
      url: `/user/list`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to get users list`);
    }

    return response.data.data;
  };

  static getUsersListFilter = async ({ filter, limit, page }) => {
    const response = await Axios({
      method: `GET`,
      params: { filter, limit, page },
      url: `/user/list/filter`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to get users list`);
    }

    return response.data.data.results;
  };

  static getDetails = async (id) => {
    const response = await Axios({
      method: `GET`,
      url: `/user/${id}/info`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to get user`);
    }

    return response.data.data.info;
  };

  static createNewUser = async (user) => {
    const response = await Axios({
      data: { user },
      method: `POST`,
      url: `/user/new`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to create user`);
    }

    return response.data.data.user;
  };

  static bulkLoadUsers = (data) =>
    Axios({
      data,
      method: `POST`,
      url: `/user/bulk`,
    });

  static disableUsers = async (ids) => {
    const response = await Axios({
      method: `DELETE`,
      url: `/user/${ids}/disable?${qs.stringify({ ids })}`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to disable user.`);
    }

    return response.data.data;
  };

  static deleteUser = async (id) => {
    const response = await Axios({
      method: `DELETE`,
      url: `/user/${id}`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to delete user.`);
    }

    return response.data.data;
  };

  static changeAvatar(data) {

    return Axios({
      data,
      method: `POST`,
      url: `/user/avatar`,
    });
  }

  static restoreUser = async (id) => {
    const response = await Axios({
      method: `POST`,
      url: `/user/${id}/restore`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to restore user`);
    }

  };

  static update = async (id, user) => {
    const response = await Axios({
      data: { user },
      method: `PUT`,
      url: `/user/${id}`,
    });
    if (response.status !== 200) {
      throw new Error(`Failed to upate user.`);
    }

  };

  static async checkForPossibleDuplicates(first_name, last_name, username, email) {
    try {
      const response = await Axios({
        method: `GET`,
        params: { email, first_name, last_name, username },
        url: `/user/duplicate-check`,
      });

      return response.data.data.users;
    }
    catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async scaryResetPassword({ confirmPassword, id, password }) {
    try {
      const response = await Axios({
        data: { confirmPassword, password },
        method: `POST`,
        url: `/user/${id}/reset-password/scary`,
      });

      return response.data;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async authenticatedPasswordReset({ confirmPassword, password }) {
    try {
      const response = await Axios({
        data: { confirmPassword, password },
        method: `POST`,
        url: `/reset/password/authenticated`,
      });

      return response.data.resetToken;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }
}
