import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { IoMdInformationCircle as AutofilledIcon } from 'react-icons/io';
import PropTypes from 'prop-types';

const autofilledTooltip = (props) =>
  <Tooltip id="autofill-tooltip" className="tooltip-no-arrow" {...props}>
    <h6>{`Auto-filled persistent response`}</h6>
  </Tooltip>;

export const AutofilledIndicator = ({ parent }) =>
{
  const { id } = parent;

  return <IconContext.Provider value={{ color: `#1f8838` }}>
    <OverlayTrigger
      placement="right"
      overlay={autofilledTooltip}
    >
      <AutofilledIcon
        size="1.4rem"
        id={`autofilledIcon-${id}`}
        className="mb-1 ml-1" />
    </OverlayTrigger>
  </IconContext.Provider>;
};

autofilledTooltip.propTypes = {
  props: PropTypes.object,
};
