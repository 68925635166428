import { Axios } from 'shared/utils';

export class NotificationService {

  static async getLatest() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/notification/latest`,
      });

      return response.data.data.notifications;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getUnreadCount() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/notification/unread`,
      });

      return response.data.data.unread;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async markAsRead(notifications) {
    try {
      await Axios({
        data: { notifications },
        method: `POST`,
        url: `/notification/read`,
      });

    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

  static async getLiveToken() {
    try {
      const response = await Axios({
        method: `GET`,
        url: `/notification/token`,
      });

      return response.data.data.info;
    } catch (err) {
      throw new Error(`${err.response.statusText} - ${err.response.data.message}`);
    }
  }

}
