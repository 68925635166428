import React from 'react';
import PropTypes from 'prop-types';
import ReactNotification from 'react-notifications-component';
import { AuthProvider } from 'shared/providers';
import { BrowserRouter } from 'react-router-dom';

export const Providers = ({ children }) =>
  <React.StrictMode>
    <ReactNotification />
    <BrowserRouter>
      <AuthProvider>
        {children}
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>;
Providers.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.arrayOf(PropTypes.element),
  ]),
};
