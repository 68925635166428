import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import LottieWeb, { setSpeed } from 'lottie-web';
import { Alert } from 'react-bootstrap';
import raLottie from './lotties/RALogo.json';
import errorLottie from './lotties/error.json';
import avocadoLottie from './lotties/avocado.json';

const allLotties = {
  avocado: {
    animationData: avocadoLottie,
    autoplay: true,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: `xMidYMid slice`,
    },
  },
  error: {
    animationData: errorLottie,
    autoplay: true,
    loop: false,
    rendererSettings: {
      preserveAspectRatio: `xMidYMid slice`,
    },
  },
  ra: {
    animationData: raLottie,
    autoplay: true,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: `xMidYMid slice`,
    },
  },
};

export const Lottie = ({
  align = `center`,
  height = 200,
  lottie,
  message = ``,
  speed = 1.25,
  width = 200,
}) => {

  const id = uuidv4();
  const isLoaded = useRef(false);
  const avocadoTime = Math.floor(Math.random() * 100) + 1 === 50 && process.env.NODE_ENV === `development`;

  useEffect(() => {
    if (!isLoaded.current) {
      if (avocadoTime) {
        LottieWeb.loadAnimation({
          ...{ container: document.getElementById(id) },
          ...allLotties.avocado,
        });
      } else {
        LottieWeb.loadAnimation({
          ...{ container: document.getElementById(id) },
          ...allLotties[lottie],
        });
      }

      setSpeed(speed);
      isLoaded.current = true;
    }
  }, [ avocadoTime, lottie, id, speed ]);

  return (
    <div
      className={
        align === `center` ?
          ` justify-content-center align-items-center` :
          align === `left` ?
            `float-left` :
            align === `right` ?
              `float-right` :
              align === `none` ?
                `` :
                `d-flex justify-content-center align-items-center`
      }>
      <div
        id={id}
        style={{
          display: `block`,
          height,
          margin: `auto`,
          textAlign: `center`,
          width,
        }} />
      {
        !!(lottie === `error`) &&
        !!message.length &&
          <Alert
            className="d-flex justify-content-center align-items-center"
            style={{
              // stylelint-disable indentation
              fontWeight: 600,
              marginLeft: `25%`,
              textAlign: `center`,
              width: `50%`,
            }}
            variant="danger">
            {message}
          </Alert>
      }
    </div>
  );
};

Lottie.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lottie: PropTypes.string,
  message: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
