import { OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const ErrorWithTooltip = ({ errorMessage, errors, popoverTitle }) =>
  <OverlayTrigger
    overlay={
      <Popover id="popover-basic" className="error-popover-custom">
        <Popover.Title>
          <strong>{popoverTitle}</strong>
        </Popover.Title>
        <Popover.Content>
          {
            errors.map(error => <p style={{ color: `red` }}>&#9888; {error}</p>)
          }
        </Popover.Content>
      </Popover>
    }
    placement="right-start">
    <span style={{ borderBottom: `1px dotted red`, color: `red` }}>{errorMessage}</span>
  </OverlayTrigger >;

ErrorWithTooltip.propTypes = {
  errors: PropTypes.array.isRequired,
  message: PropTypes.string.isRequired,
};
