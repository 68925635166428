import React from 'react';
import ReactPaginate from 'react-paginate';

interface IPagination {
  currentPage: number;
  filterChanged: boolean;
  perPage: number;
  lastPage: number;
}
export const Pagination: React.FC<{
  marginPages: number;
  onPageChange: (data: { selected: number }) => Promise<void>;
  pageRange: number;
  pagination: IPagination; }
> = ({ marginPages, onPageChange, pageRange, pagination }) =>
  <div className="d-flex">
    <div className="ml-auto" data-testid="pagination">
      <ReactPaginate
        pageCount={pagination.lastPage}
        pageRangeDisplayed={pageRange}
        marginPagesDisplayed={marginPages}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="page-item active"
        forcePage={pagination.filterChanged ? pagination.currentPage - 1 : 0}
        onPageChange={onPageChange}
      />
    </div>
  </div>;
