import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { PromiseForm } from 'shared/A-UI';
import { useAuth } from 'shared/providers';

export const ImageModal: React.FC<
{ closeUploadModal: () => Promise<void>;
  file: string;
  handleChange: () => Promise<void>;
  onSubmit: () => Promise<void>;
  showUploadModal: boolean;
  type: string; }> = ({
  closeUploadModal,
  file,
  handleChange,
  onSubmit,
  showUploadModal,
  type,
}) => {
  const { handleSubmit, register } = useForm();
  const { terms: { offender_term } } = useAuth();
  const styles = { align: `center`, height: `200px`, width: `200px` };

  if (type === `offender`) { type = offender_term; }

  return <Modal aria-labelledby="msg" data-testid="profileModal" show={showUploadModal} onHide={closeUploadModal}>
    <PromiseForm onSubmit={handleSubmit(onSubmit)}>
      <Modal.Header >
        <Modal.Title id="msg">CHANGE {type.toUpperCase()} IMAGE</Modal.Title>
        <CloseButton data-testid="closeBtn" onClick={closeUploadModal} />
      </Modal.Header>
      <Modal.Body>

        <input
          {...register(`file`)}
          type="file"
          name="file"
          data-testid="profileInput"
          required
          onChange={handleChange}
          aria-label="Select file" />
        <br />
        {
          !!file &&
            <div className="row">
              <div className="col-md-12">
                <img
                  className="img-thumbnail"
                  src={file}
                  alt="profile avatar"
                  style={file ? styles : undefined}
                />
              </div>
            </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="custom" data-testid="profileSave" >
          Submit
        </Button>
      </Modal.Footer>
    </PromiseForm>
  </Modal>;
};
